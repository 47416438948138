.questions {
    margin-top: ac(140px, 65px);
    
    &__row {
        display: flex;

        &:not(:last-child) {
            margin-bottom: ac(99px, 30px);
        }
    }

    &__title {
        position: relative;
        width: 100%;
        max-width: ac(520px, 280px);
        flex: 0 0 auto;
        margin-right: 20px;

        &::before {
            content: '';
            position: absolute;
            top: ac(15px, 10px);
            left: ac(15px, 10px);
            width: ac(132px, 60px);
            height: ac(132px, 60px);
            transform: rotate(15deg);
            background-color: var(--cyan);
            z-index: -1;
        }
    }

    &__descr {
        margin-top: ac(135px, 20px);
        font-size: ac(30px, 25px);
        line-height: ac(40px, 35px);
        font-weight: 500;
    }

    @mixin tab {
        &__row {
            flex-direction: column;
        }

        &__descr {
            margin-top: ac(50px, 20px);
            margin-left: ac(200px, 0px);
        }
    }
}
.find {
    position: relative;
    margin-top: ac(139px, 120px);
    padding-bottom: ac(130px, 30px);

    &__head {
        display: flex;
        flex-wrap: wrap;

        .find__title, .find__subtitle {
            max-width: 520px;
        }

        .find__title {
            margin-right: 20px;
            text-align: left;
        }

        .find__subtitle {
            margin-left: auto;
          
            margin-top: ac(95px, 35px);
            max-width: 520px;
        }
    }

    &__img-box {
        position: absolute;
        width: ac(1168px, 641px);
        height: ac(700px, 411px);
        top: ac(-88px, -240px);
        left: ac(-280px, -341px);
        z-index: -1;

        .swiper-slide {
            opacity: 0;
            transition: .3s ease;

            &-active {
                opacity: 1;
            }
        }

        img {
            //height: 140%;
            //object-fit: cover;
            //width: 100%;
            //object-position: 68% ac(-324px, -130px);
        }
    }

    &__mask {
        height: 100%;
        clip-path: url("#find-mask");
        overflow: hidden;

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: left;
        }
    }

    &__wrapper {
        position: relative;
        width: 100%;
        margin-top: ac(164px, 200px);
    }

    &__title-box {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        max-width: ac(942px, 350px);
        margin-left: auto;
        padding: ac(89px, 36px) 0 ac(151px, 66px);

        &::before {
            content: '';
            display: block;
            position: absolute;
            left: ac(28px, -63px);
            top: ac(-75px, -26px);
            width: calc(100% + 50vw);
            height: 100%;
            background-color: var(--bluish);
            transform: rotate(-13deg);
            z-index: -1;
        }
    }

    &__title {
        text-align: right;
    }

    &__subtitle {
        margin-top: ac(30px, 37px);
        text-align: right;
        max-width: ac(844px, 290px, 320, 1440);
        font-size: ac(30px, 20px);
        line-height: ac(40px, 30px);
        font-weight: 500;
    }

    &--sector,
    &--months {
        .find {
            &__img-box {
                display: none;
            }

            &__wrapper {
                margin-top: ac(164px, 100px);
                display: flex;
                align-items: flex-start;
            }
      
            &__months-box {
                position: relative;
                padding: 35px 0 130px;
                margin-top: 150px;
                width: 100%;
                max-width: 522px;

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    right: ac(-120px, 0px);
                    top: ac(-75px, -26px);
                    width: calc(100% + 50vw);
                    height: 100%;
                    background-color: var(--midnight);
                    transform: rotate(-170.52deg);
                    z-index: -1;
                }

                &>p {
                    margin-top: ac(44px, 20px);
                    font-weight: 500;
                    font-size: ac(50px, 25px);
                    line-height: ac(60px, 35px);
                    color: var(--white);
                }
            }

            &__month {
                color: var(--white);
                font-weight: 400;
                font-size: ac(70px, 35px);
                font-family: var(--font-sec);

                span {
                    display: inline-block;
                    min-width: ac(150px, 70px, 1024, 1440);
                    font-size: ac(230px, 100px);
                    line-height: ac(130px, 40px);
                    margin-right: 11px;
                }
            }

            &__title-box {
                max-width: ac(628px, 350px);
                padding: ac(159px, 36px) 0 ac(142px, 66px);

                &::before {
                    left: ac(-100px, -50px, 1024, 1440);
                    top: ac(12px, 30px, 1024, 1440);
                    height: 84%;
                    background-color: var(--grass);
                    transform: rotate(-9.48deg);
                }

                .btn {
                    margin-top: ac(34px, 25px);
                    background-color: var(--white);

                    &:hover {
                        background-color: var(--midnight);
                        color: var(--white);
                    }
                }
            }
        }
    }

    &--sector {
        padding-bottom: 0;

        .find__title-box:before {
            background-color: var(--bluish);
        }

        .find__subtitle {
            max-width: ac(550px, 290px, 320, 1440);
        }

        .find__months-box>p {
            margin-top: 0;
            padding-right: ac(0px, 100px, 1024, 1440);
        }

        .find__months-box {
            padding: 35px 0 182px;
        }
    }

    &--cookie {
        margin-top: ac(230px, 390px);

        .find {
            &__img-box {
                position: absolute;
                width: ac(1168px, 641px);
                height: ac(720px, 411px);
                top: ac(-42px, -330px);
                left: ac(-400px, -200px);
                z-index: -1;
        
                img {
                    height: 100%;
                    object-position: -78px 10px;
                    width: 95%;
                }
            }

            &__title-box {
                max-width: ac(622px, 350px);
                padding-top: ac(81px, 25px);
                padding-bottom: ac(106px, 125px);
                text-align: right;
                color: var(--white);

                p:not(.find__subtitle) {
                    margin-top: ac(21px, 15px);
                }

                &::before {
                    left: ac(-102px, -50px);
                    top: -54px;
                    background-color: var(--mint);
                    transform: rotate(-9.48deg);
                }
            }

            &__btn-box {
                display: flex;
                margin-top: ac(33px, 20px);
                
                .btn {
                    &:first-child {
                        background-color: var(--grass);
                        margin-right: ac(20px, 10px);
                    }

                    &:last-child {
                        background-color: var(--white);
                    }

                    &:hover {
                        background-color: var(--midnight);
                        color: var(--white);
                    }
                }
            }
        }
    }

    &--done {
        .find {
            &__img {
                position: absolute;
                left: calc(50% + ac(226px, -15px, 320, 1440));
                z-index: 1;
                top: ac(108px, -140px, 658, 1440);
                width: ac(412px, 150px, 320, 1440);
                height: ac(417px, 150px, 320, 1440);

                img {
                    object-fit: contain;
                }
            }

            &__img-box {
                display: none;
            }

            &__title-box {
                margin-left: 0;
                max-width: ac(750px, 350px);
                align-items: flex-start;
                padding: ac(83px, 36px) 0 ac(142px, 66px);

                &::before {
                    left: unset;
                    right: ac(-169px, -63px);
                    top: ac(-49px, -26px);
                    background-color: var(--grass);
                    transform: rotate(6.44deg);
                }

                p:not(.find__subtitle) {
                    margin-top: ac(21px, 15px);
                }
            }

            &__subtitle, &__title {
                text-align: left;
            }

        }
    }
   
    &--grow {
        margin-top: 242px;

        .find {
            &__title-box {
                &::before {
                    transform: rotate(-9deg);
                    left: ac(12px, -63px);
                    top: ac(-70px, -26px);
                }
            }

            &__subtitle {
                margin-top: ac(41px, 37px);
                margin-bottom: ac(20px, 15px);
            }

            &__img-box {
                width: ac(1000px, 641px);
                height: ac(600px, 411px);
                top: ac(70px, -240px);
                left: ac(-280px, -341px);
            }

        }

        p {
            text-align: right;
            max-width: 628px;
        }

        .btn {
            margin-top: 33px;
        }
    }

    @media (min-width: 1600px) {
        &__title-box {
            padding-top: ac(160px, 100px, 1600, 2560);
        }

        &__img-box {
            height: ac(850px, 700px, 1600, 2560);
            left: ac(-1000px, -400px, 1600, 2560);
            width: ac(1768px, 1268px, 1600, 2560);
        }

        &--cookie {
            .find {
                &__img-box {
                    height: ac(745px, 650px, 1600, 2560);
                    left: ac(-880px, -400px, 1600, 2560);
                    width: ac(1768px, 1268px, 1600, 2560);
                    top: ac(-22px, -330px);
                }
            }
        }

        &--grow {
            .find {
                &__img-box {
                    height: ac(745px, 650px, 1600, 2560);
                    left: ac(-1000px, -400px, 1600, 2560);
                    width: ac(1768px, 1268px, 1600, 2560);

                    img {
                        object-position: 68% ac(-230px, 0px, 1600, 2560);
                    }
                } 
            }
        }
    }

    @mixin tab {
        &--sector,
        &--months {
            .find__wrapper {
                flex-direction: column;
            }

            .find__title-box {
                margin-top: ac(-100px, -55px, 320, 1024);
            }

            .find__title-box:before {
                height: 100%;
                left: ac(-100px, -60px, 320, 1024);
                top: -30px;
            }

            .find__months-box {
                margin-top: 0;
            }
        }

        &--sector {
            .find__months-box>p {
                padding-right: 50px;
            }

            .find__months-box {
                padding: 35px 0 130px;
            }
        }
    }

    @mixin mob-xl {
        &__title-box {
            align-items: flex-start;
        }

        &__title, &__subtitle {
            text-align: left;
        }

        &__head {
            .find__subtitle {
                text-align: right;
            }
        }
    } 
}
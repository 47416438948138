.clients {
    margin-top: ac(89px, 72px);

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-right: ac(-92px, -20px, 769, 1440);
        margin-bottom: ac(-27px, -44px);
        padding: 0 ac(108px, 0px, 1024, 1440);
    }

    &__logo {
        width: calc(100% / 4 - ac(92px, 20px, 769, 1440));
        height: ac(80px, 60px);
        margin-right: ac(92px, 20px, 769, 1440);
        margin-bottom: ac(27px, 44px);

        img {
            object-fit: contain;
        }
    }

    &--sector {
        margin-top: ac(99px, 72px);
    }

    @mixin tab-sm {
        &__logo {
            width: calc(100% / 2 - 20px);
        }
    }
}
.benefits {
    margin-top: ac(65px, 45px);

    &__wrapper {
        display: flex;
        flex-direction: column;
        padding-left: ac(110px, 0px, 768, 1440);
    }

    &__row {
        display: flex;
        padding-top: ac(45px, 20px);
        padding-bottom: ac(48px, 21px);
        border-bottom: 1px solid var(--midnight);

        &:first-child {
            border-top: 1px solid var(--midnight);
        }

        &:nth-child(even) {
            .benefits__num {
                color: var(--bluish);
            }

            .benefits__col {
                span {
                    color: var(--bluish);
                }
            }
        }
    }

    &__num {
        min-width: ac(110px, 80px);
        margin-right: ac(104px, 20px, 768, 1440);
        font-size: ac(100px, 60px);
        line-height: ac(100px, 60px);
        color: var(--cyan);
        font-family: var(--font-sec);
    }

    &__title {
        width: 100%;
        font-size: ac(30px, 20px);
        line-height: ac(40px, 30px);
        font-weight: 500;
    }

    &__text {
        margin-top: 6px;
        display: flex;
        flex-wrap: wrap;
    }

    &__col {
        max-width: ac(411px, 280px, 768, 1440);
        margin-top: ac(30px, 10px);
        max-height: ac(90px, 84px);
        @mixin max-line-length 3;

        span {
            color: var(--cyan);
        }

        &:not(:last-child) {
            margin-left: auto;
            margin-right: ac(22px, 10px, 768, 1440);
        }
    }

    @mixin tab-sm {
        &__row {
            flex-direction: column;
        }

        &__col {
            max-width: calc(100% / 2 - ac(22px, 10px, 320, 768));

            &:not(:last-child) {
                margin-left: 0;
                margin-right: ac(22px, 10px, 320, 768);
            }
        }
    }

    @mixin mob-lg {
        &__col {
            max-width: 100%;

            &:not(:last-child) {
                margin-right: 0;
            }
        }
    }
}
.stuff {
    margin-top: ac(63px, 25px);

    &__title-box {
        display: flex;
    }

    &__title {
        max-width: ac(736px, 550px, 1024, 1440);
        flex: 0 0 auto;
        margin-right: ac(20px, 0px, 768, 1440);
    }

    &__subtitle {
        margin-top: ac(55px, 20px, 768, 1440);
        margin-left: auto;
        font-size: ac(30px, 20px, 1024, 1440);
        line-height: ac(40px, 30px);
        font-weight: 500;
        max-width: 520px;
        text-align: right;
    }

    &__slider-box {
        margin-top: ac(60px, 25px);
    }

    &__card {
        padding: ac(40px, 25px, 1024, 1440) ac(40px, 25px, 1024, 1440) ac(30px, 20px, 1024, 1440);
        background-color: var(--grass);
        height: 100%;
    }

    &__head {
        justify-content: space-between;
        align-items: flex-start;
    }

    &__step {
        position: relative;
        font-weight: 500;
        font-size: 15px;
        line-height: 15px;
        margin-left: ac(-13px, -5px);
        padding: 18px 11px 18px 13px;
        z-index: 1;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--mint);
            transform: rotate(-4deg);
            z-index: -1;
        }
    }

    &__name {
        margin-top: 21px;
        min-height: ac(120px, 88px, 1024, 1440);
        display: flex;
        align-items: flex-end;
        font-weight: 500;
        font-size: ac(50px, 34px, 1024, 1440);
        line-height: ac(60px, 44px, 1024, 1440);
    }

    &__icon {
        width: 60px;
        height: 60px;

        img {
            object-fit: contain;
            object-position: center top;
        }
    }

    &__descr {
        margin-top: ac(38px, 20px);
        max-height: ac(120px, 112px);
        width: calc(100% + 15px);
        padding-right: 15px;

        .simplebar-vertical {
            width: 7px;
            background-color: rgba(2, 2, 56, .3);
            border-radius: 7px;
        }

        .simplebar-scrollbar::before {
            opacity: 1;
            background-color: var(--midnight);
            left: 0;
            right: 0;
        }

        .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
            top: 0;
            bottom: 0;
        }

        a {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 4px;
                width: 100%;
                height: 1px;
                background: var(--midnight);
                transition: .3s ease;
            }

            &:hover {
                &::before {
                    background-color: var(--cyan);
                }
            }
        }
    }

    @mixin tab {
        &__title-box {
            flex-direction: column;
        }
    }
}
.career {
    margin-top: ac(141px, 65px);

    &__title-box {
        display: flex;
    }

    &__title {
        max-width: ac(736px, 550px, 1024, 1440);
        flex: 0 0 auto;
        margin-right: ac(20px, 0px, 768, 1440);
    }

    &__subtitle {
        margin-top: ac(95px, 20px);
        margin-left: auto;
        font-size: ac(30px, 20px, 1024, 1440);
        line-height: ac(40px, 30px);
        font-weight: 500;
        max-width: 520px;
        text-align: right;
    }

    &__text {
        display: flex;
    }

    &__col {
        margin-top: ac(41px, 20px);
        width: 100%;
        max-width: 520px;
        margin-right: 20px;
    }

    &__slider-box {
        margin-top: 59px;
    }

    &__slide {
        &:first-child {
            .career__btn {
                display: none;
            }
        }
    }

    &__card {
        position: relative;
        padding: ac(39px, 15px, 320, 1440);
        border: 1px solid var(--midnight);
        height: 100%;
        transition: .3s ease;

        &:hover {
            background-color: var(--mint);
            color: var(--white);

            .career__btn {
                color: var(--midnight);
            }
        }
    }

    &__btn {
        position: absolute;
        left: -11px;
        top: 50%;
        width: ac(64px, 40px);
        height: ac(64px, 40px);
        transform: translate(-50%, -50%);

        span {
            font-size: ac(24px, 16px);
            line-height: ac(64px, 40px);
        }

        &::before {
            background-color: var(--grass);
        }

        &:hover {
            span {
                color: var(--midnight);
            }

            &::before {
                background-color: var(--grass);
            }
        }
    }

    &__name {
        font-size: ac(30px, 25px);
        line-height: ac(40px, 35px);
        font-weight: 500;
    }

    &__descr {
        margin-top: 24px;
    }

    @mixin tab {
        &__col {
            margin-right: 0;
            max-width: 100%;
        }

        &__text,
        &__title-box {
            flex-direction: column;
        }
    }
}
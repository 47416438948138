.discount {
    margin-top: ac(80px, 62px);

    &--sector {
        margin-top: ac(94px, 62px);
    }

    &__wrapper, &__val {
        display: flex;
        align-items: flex-end;
    }

    &__val {
        min-width: ac(346px, 250px, 769, 1440);
        flex: 0 0 auto;
        margin-right: ac(86px, 20px, 769, 1440);
        font-size: ac(230px, 130px);
        line-height: ac(150px, 90px);
        font-family: var(--font-sec);

        span {
            &:nth-child(2) {
                font-size: ac(100px, 62px);
                line-height: ac(75px, 50px);
            }
        }
    }

    &__descr {
        max-width: 648px;
        font-size: ac(50px, 35px);
        line-height: ac(60px, 45px);
        font-weight: 500;
    }

    &--grow {
        position: relative;
        margin-top: ac(139px, 65px);
        padding-bottom: ac(50px, 25px);

        .discount {
            &__bg {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: -1;

                img {
                    transform: scaleX(-1);
                }
            }

            &__wrapper {
                flex-direction: column;
                align-items: flex-start;
            }

            &__descr {
                margin-top: ac(103px, 35px);
                margin-right: ac(-10px, 0px, 1024, 1440);
                margin-left: auto;
                color: var(--white);
                text-align: right;
            }

            &__val {
                position: relative;
                padding: ac(83px, 25px) 0 ac(75px, 20px);
                margin-right: 0;

                &::before {
                    content: '';
                    position: absolute;
                    right: ac(-175px, -20px, 320, 1440);
                    top: 0;
                    width: calc(100% + 50vw);
                    height: 100%;
                    background-color: var(--white);
                    z-index: -1;
                }
            }
        }
    }

    @mixin tab-sm {
        &__wrapper {
            flex-direction: column;
            align-items: flex-start;
        }

        &__val {
            min-width: unset;
            margin-right: 0;
        }

        &__descr {
            margin-top: 27px;
            max-width: 100%;
            padding-right: 20px;
        }
    }
}

.first {
    padding-top: ac(211px, 174px);
    
    &, &__wrapper, &__input {
        position: relative;
    }

    &__wrapper, &__form {
        display: flex;
    }

    &__wrapper, &__title, &__form, &__input, &__input input, &__mask {
        width: 100%;
    }

    &__wrapper {
        flex-direction: column;
        align-items: flex-start;
        padding: ac(89px, 36px) 0 ac(113px, 55px);
        max-width: ac(942px, 350px);

        &::before {
            content: '';
            display: block;
            position: absolute;
            right: ac(0px, -42px);
            top: ac(-55px, 0px);
            width: calc(100% + 50vw);
            height: 100%;
            background-color: var(--cyan);
            transform: rotate(12deg);
            z-index: -1;
        }
    }

    &__img-box {
        position: absolute;
        width: ac(1168px, 641px);
        height: ac(749px, 411px);
        top: ac(240px, 336px);
        right: ac(-280px, -285px);
        z-index: -1;
    }

    &__mask {
        height: 100%;
        clip-path: url("#first-mask-1");
        overflow: hidden;

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: left;
        }
    }

    &__title {
        max-width: ac(698px, 360px);
        color: var(--white);

        &>span {
            margin: ac(0px, -5px) 0 0 ac(0px, 16px);
            display: inline-block;
            font-size: ac(100px, 70px);
            line-height: ac(90px, 60px);
            font-family: var(--font-sec);
            color: var(--midnight);
            transform: rotate(0deg) translate(-12px, 2px);
        }

        &--sec {
            font-size: ac(80px, 38px);
            line-height: ac(90px, 48px);

            &>span {
                font-size: ac(100px, 64px);
                line-height: ac(90px, 60px);
            }
        }
    }

    &__form {
        margin-top: ac(26px, 18px);
    }

    &__label {
        flex: 0 0 auto;
        margin-right: ac(29px, 15px);
        padding: 11px 0 12px;
        letter-spacing: 0.2px;
        color: var(--white);
    }

    &__input {
        max-width: ac(473px, 200px);

        input {
            padding: 11px 40px 11px 0px;
            color: var(--white);
            border-bottom: 1px solid var(--white);
            background-color: transparent;
            border-radius: 0;
            transition: .3s ease;

            &:focus {
                &::placeholder {
                    opacity: 0;
                }
            }

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
                transition: background-color 5000s ease-in-out 0s;
                -webkit-text-fill-color: var(--white) !important;
            }

            &::placeholder {
                color: var(--white);
                opacity: .5;
                transition: .3s ease;
            }
        }

        .arrow-btn {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
        }
    }

    &--about {
        padding-top: ac(217px, 150px, 320, 1440);
        padding-bottom: ac(142px, 76px, 320, 1440);

        .first {
            &__img-box {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                img {
                    object-position: 50% 48%;
                }
            }

            &__wrapper {
                padding: ac(83px, 36px) 0 ac(125px, 55px);

                &::before {
                    right: ac(345px, 20px);
                    top: ac(-128px, -25px);
                    background-color: var(--white);
                }
            }

            &__title {
                color: var(--midnight);

                &>span {
                    font-size: ac(90px, 44px, 320, 1440);
                    margin: 8px 0 0 12px;
                }
            }
        }
    }

    &--work {
        padding-top: ac(217px, 150px, 320, 1440);
        padding-bottom: ac(142px, 76px, 320, 1440);

        .first {
            &__img-box {
                padding-top: 54px;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &__wrapper {
                padding: ac(48px, 36px) 0 ac(145px, 55px);

                &::before {
                    right: ac(345px, 20px);
                    top: ac(-128px, -25px);
                    background-color: var(--mint);
                }
            }

            &__subtitle {
                font-weight: 500;
                font-size: 15px;
                line-height: 15px;
                color: var(--white);
            }

            &__title {
                margin-top: ac(20px, 15px);
                line-height: ac(76px, 50px);

                &>span {
                    font-size: ac(78px, 44px, 320, 1440);
                    margin: 8px 0 0 12px;
                }
            }

            &__btn-box {
                display: flex;
                margin-top: ac(36px, 25px);
                
                .btn {
                    &:first-child {
                        background-color: var(--white);
                        margin-right: ac(20px, 15px);
                    }

                    &:last-child {
                        background-color: var(--grass);
                    }

                    &:hover {
                        background-color: var(--midnight);
                        color: var(--white);
                    }
                }
            }
        }
    }

    &--grow {
        padding-top: ac(211px, 150px, 320, 1440);
        padding-bottom: ac(170px, 70px, 320, 1440);

        .first {
            &__img-box {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                img {
                    object-position: calc(50% - 3px) calc(50% + 11px);
                }
            }

            &__wrapper {
                margin: 0 auto;
                align-items: center;
                max-width: ac(628px, 260px, 320, 1440);

                &::before {
                    left: -17px;
                    top: -2px;
                    opacity: .8;
                    height: 96%;
                    width: 105%;
                }
            }
        }
    }

    &--sector {
        padding-top: ac(206px, 160px, 320, 1440);

        .first__decor {
            position: absolute;
            bottom: ac(-44px, -240px);
            right: ac(81px, 40px);
            width: ac(89px, 69px);
            height: ac(99px, 79px);

            img {
                object-fit: contain;
            }
        }

        .first__wrapper {
            padding-top: ac(52px, 0px, 320, 1440);
            padding-bottom: ac(122px, 74px, 320, 1440);

            &::before {
                right: ac(107px, -55px, 320, 1440);
                top:ac(-66px, -28px, 320, 1440);
            }
        }

        .first__img-box {
            width: ac(1100px, 500px, 320, 1440);
            height: ac(700px, 350px, 320, 1440);
            right: ac(-170px, -93px);
            top: ac(154px, 376px, 320, 1440);
        }

        .first__mask {
            clip-path: url("#first-mask-2");
            transform: rotate(1.5deg);
            
            video, img {
                transform: rotate(-1.5deg);
            }
        }

        .first__form {
            margin-top: ac(28px, 15px);
        }

        .first__subtitle {
            color: var(--white);
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 12px;
        }

        .first__title {
            width: 100%;
            max-width: ac(760px, 360px);
            font-size: ac(80px, 30px, 320, 1440);
            line-height: ac(90px, 40px, 320, 1440);
    
            &>span {
                font-size: ac(74px, 26px);
                line-height: ac(74px, 20px);
                transform: rotate(-2.74deg) translate(-8px,ac(6px, 4px));
            }
        }
    }
    
    @media (min-width: 426px) {
        &__title--sec {
            br {
                display: none;
            }
        }
    }
    
    @media (min-width: 1920px) {
        &__wrapper {
            padding-top: 165px;
        }

        &__img-box {
            width: 1668px;
            height: 950px;

            &--home {
                video {
                    height: 120%;
                }
            }
        }

        &--sector {
            .first__wrapper {
                padding-top: 165px;
            }

            .first__decor {
                bottom: -160px;
            }
    
            .first__img-box {
                right: -200px;
                width: 1768px;
                height: 1050px;
            }
        }
    }

    @mixin mob-xl {
        &__form {
            flex-direction: column;
        }

        &__label {
            margin-right: 0;
            padding-bottom: 0;
        }

        &__input {
            max-width: 300px;
        }

        &--work {
            .first__btn-box {
                flex-direction: column;

                .btn {
                    min-width: 200px;
                    
                    &:first-child {
                        margin-right: 0;
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }

    @mixin mob-sm {
        &__title {
        }

        &--about {
            .first__wrapper {
                &::before {
                    right: ac(345px, 50px, 320, 1440);
                    top: ac(-128px, -27px, 320, 1440);
                }
            }
        }
    }
}
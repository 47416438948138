.details {
    margin-top: ac(156px, 100px);

    .btn {
        background-color: var(--cyan);

        &:hover {
            background-color: var(--midnight);
        }
    }

    &__title-box {
        display: flex;
    }

    &__title {
        margin-right: ac(20px, 0px);
    }

    &__icon {
        margin-top: ac(15px, 5px);
        width: ac(196px, 66px);
        height: ac(185px, 55px);
        flex: 0 0 auto;
        margin-left: auto;

        img {
            object-fit: contain;
            object-position: right center;
        }
    }

    &__salary-box {
        margin-top: ac(59px, 25px);
        display: flex;
        max-width: 756px;
        padding: ac(30px, 15px) 0;
        border-top: 1px solid var(--midnight);
        border-bottom: 1px solid var(--midnight);
    }

    &__location {
        margin-right: ac(125px, 0px);
    }

    &__location, &__salary, &__descr, &__side-title {
        font-size: ac(30px, 20px);
		line-height: ac(40px, 30px);
        font-weight: 500;
    }

    &__info {
        display: flex;
        margin-top: ac(50px, 25px);
    }

    &__col {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 20px;
        margin-top: ac(-39px, -15px);

        &>* {
            margin-top: ac(39px, 15px);
        }
    }

    &__share {
        p {
            font-size: 15px;
            line-height: 15px;
            font-weight: 500;
            margin-bottom: 15px;
        }
    }

    &__soc-box {
        display: flex;
        margin-right: -12px;
        margin-left: auto;
    }

    &__soc {
        display: flex;
        justify-content: center;
        width: 33px;
        height: 33px;
        background-color: var(--midnight);
        margin-right: 12px;
        cursor: pointer;

        span {
            color: var(--white);
            line-height: 33px;
        }

        &:hover {
            background-color: var(--cyan);
        }
    }

    &__descr {
        margin-left: auto;
        text-align: right;
        max-width: 736px;
        font-size: ac(30px, 20px);
		line-height: ac(40px, 30px);
    }

    &__wrapper {
        margin-top: ac(100px, 35px);
        display: flex;
    }

    &__sidebar {
        flex: 0 0 auto;
        width: ac(412px, 350px, 1024, 1440);
        margin-left: ac(128px, 25px, 1024, 1440);
    }

    &__sticky {
        position: sticky;
        top: 60px;
    }

    &__bottom {
        margin-top: ac(51px, 30px);
    }

    &__card {
        margin-bottom: ac(40px, 20px);
        margin-top: ac(19px, 15px);
        display: flex;
    }

    &__photo {
        flex: 0 0 auto;
        width: 88px;
        height: 88px;
        margin-right: ac(20px, 15px);
    }

    &__text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__name {
        margin-top: ac(-6px, 0px, 1024, 1440);
        font-weight: 500;
    }

    @mixin tab {
        &__wrapper {
            flex-direction: column-reverse;
        }

        &__sidebar {
            width: 100%;
            margin-left: 0;
            margin-bottom: ac(60px, 30px);
        }

    }

    @mixin tab-sm {
        &__info {
            flex-direction: column-reverse;
        }

        &__col {
            margin-right: 0;
            flex-flow: row wrap;
            align-items: flex-end;
            justify-content: space-between;

            .btn {
                margin-right: 20px;
            }
        }

        &__descr {
            text-align: left;
            margin-bottom: ac(40px, 15px);
        }
    }

    @mixin mob-xl {
        &__salary-box {
            flex-direction: column;
        }

        &__location {
            margin-right: 0;
            margin-bottom: 10px;
        }
    }

    @mixin mob-lg {
        &__title-box {
            flex-direction: column-reverse;
        }

        &__title {
            margin-top: 5px;
        }
    }

    @mixin mob-sm {
        &__card {
            flex-direction: column;
        }

        &__photo {
            margin-bottom: 20px;
        }
    }
}
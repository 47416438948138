html {
	font-size: ac(18px, 16px);
	line-height: ac(30px, 28px);
	font-family: var(--font-main);
	color: var(--midnight);
	scrollbar-width: thin;
	scrollbar-color: transparent var(--cyan);

	&::-webkit-scrollbar {
		width: 8px;
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--cyan);
	}
}

body {
	min-width: 320px;
	margin: 0;
	background-color: var(--white);

	&.no-scroll {
		overflow: hidden;
	}
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

.swiper-wrapper {
	left: 0 !important;
}

img {
	transition: opacity 0.1s ease;
	
	&.lazyload {
	   opacity: 0;
	}
 
	&.lazyloaded {
	   opacity: 1;
	}
 }

#site {
	position: relative;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.main {
	flex-grow: 1;
}

.container {
	width: 100%;
	max-width: 1352px;
	margin: 0 auto;
	padding: 0 38px;
}

img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

#bold-credits {
	width: 150px;
	transition: ease-in-out 0.25s;

	&::before,
	&::after {
		display: none;
	}

	&:hover {
		transform: scale(1.1);
	}
}

.btn {
	display: inline-flex;
	padding: 9px;
	justify-content: center;
	min-width: 140px;
	font-size: 15px;
	font-weight: 500;
	background-color: var(--bluish);
	transition: 0.3s ease;

	&:hover {
		cursor: pointer;
		color: var(--white);
		background-color: var(--midnight);
	}

	&--cyan {
		background-color: var(--cyan);
		color: var(--white);
	}

	&--tilt-wh,
	&--tilt-grey {
		position: relative;
		background-color: transparent;

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: var(--white);
			transform: rotate(-3.7deg);
			z-index: -1;
			transition: 0.3s ease;
		}

		&:hover {
			background-color: transparent;

			&::before {
				background-color: var(--midnight);
			}
		}
	}

	&--tilt-grey {
		&::before {
			background-color: var(--smoke);
		}

		&:hover {
			color: var(--white);

			&::before {
				background-color: var(--midnight);
			}
		}
	}
}

.arrow-btn {
	flex: 0 0 auto;
	display: flex;
	align-content: center;
	justify-content: center;
	position: relative;
	width: 25px;
	height: 25px;
	transition: 0.3s ease;
	z-index: 1;

	span {
		font-size: 8px;
		line-height: 25px;
		transition: 0.3s ease;
	}

	&::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: var(--white);
		transform: rotate(-15deg);
		z-index: -1;
		transition: 0.3s ease;
	}

	&:hover {
		&::before {
			background-color: var(--midnight);
		}

		span {
			color: var(--white);
		}
	}

	&--lg {
		width: ac(132px, 116px);
		height: ac(132px, 116px);

		span {
			font-size: ac(40px, 36px);
			line-height: ac(132px, 116px);
		}

		&::before {
			background-color: var(--cyan);
		}
	}

	&--md {
		width: ac(80px, 54px);
		height: ac(80px, 54px);

		span {
			font-size: ac(30px, 22px);
			line-height: ac(80px, 54px);
		}

		&::before {
			background-color: var(--cyan);
		}

		&.bluish {
			&::before {
				background-color: var(--bluish);
			}
		}

		&:hover {
			span {
				color: var(--midnight);
			}

			&::before {
				background-color: var(--white);
			}
		}
	}
}

.form {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-between;

	&>p {
		margin-bottom: ac(42px, 20px);
		font-size: ac(30px, 25px);
		line-height: ac(40px, 35px);
		font-weight: 500;
	}

	input, textarea {
		border-radius: 0;
		border-bottom: 1px solid var(--midnight);
		@mixin transparent-bg-input var(--midnight);
		transition: .3s ease;


	}

	input {
		padding-bottom: ac(11px, 8px);
	}

	label {
		display: flex;
		flex-direction: column;
		width: calc(100% / 2 - 10px);
		margin-bottom: ac(23px, 15px);

		&:hover {
			border-color: var(--cyan);
		}

		span {
			font-weight: 500;
			margin-bottom: ac(10px, 6px);
		}
	}

	textarea {
		resize: none;
		height: ac(102px, 60px);
	}

	button {
		margin-left: auto;
		margin-top: 7px;
	}

	.btn-box {
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: flex-end;
		margin-top: ac(23px, 15px);

		.agreement {
			margin-right: 15px;
		}

		.checkBox  {
			margin-bottom: 10px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.upload-input {
		width: 100%;
		display: flex;
		flex-direction: column;

		.upload-label {
			font-weight: 500;
			margin-bottom: ac(10px, 6px);
		}

		.file-input-upload {
			display: none;
		}

		.custom-file-upload {
			position: relative;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			border: 1px solid #020238;
			border-radius: 75px;
			height: 56px;
			margin-bottom: 0;
			cursor: pointer;
			transition: 0.3s ease-in-out all;

			span {
				margin-bottom: 0;
				color: #020238;
				font-size: 16px;
				line-height: 20px;
				transition: 0.3s ease-in-out all;
			}

			&:hover {
				border-color: #3282E0;
				background-color: #3282E0;

				span {
					color: #FFFFFF;
				}
			}
		}
	}

	.agreement {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.checkBox {
		display: flex;
		position: relative;
		width: 100%;

		input {
			left: 0;
			opacity: 0;
			position: absolute;
			top: 0;
		}
		input:checked + span:before {
			background: var(--cyan);
		}

		span {
			padding-left: ac(35px, 30px);
			margin-bottom: 0;
			font-size: ac(18px, 14px);
			line-height: ac(30px, 22px);

			a {
				display: inline-block;
				color: var(--bluish);

				&:hover {
					color: var(--cyan);
				}
			}

			&::before {
				content: '';
				background: transparent;
				border: 1px solid #355469;
				box-sizing: border-box;
				content: "";
				height: 20px;
				left: 0;
				position: absolute;
				top: ac(5px, 0px);
				transition: all .3s ease-out;
				width: 20px;
				cursor: pointer;
			}
		}
	}


}

.popup__block--green {
	.popup__btn-close {
		&:hover {
			.cross-clip {
				background: var(--grass);
			}
		}
	}

	.form {
		&>label {
			&:hover {
				input {
					border-color: var(--grass);
				}
			}
		}
		.btn {
			background-color: var(--grass);

			&:hover {
				background: var(--midnight);
				color: var(--white);
			}
		}

		.upload-input {
			.custom-file-upload {

				&:hover {
					span {
						color: var(--midnight);
					}

					border-color: var(--midnight);
					background-color: var(--grass);
				}
			}
		}

		.checkBox {
			input:checked + span:before {
				background: var(--grass);
			}

			span {
				a {
					color: var(--mint);

					&:hover {
						color: var(--grass);
					}
				}
			}
		}
	}
}

.choices {
	&__inner {
		min-height: ac(42px, 37px);
		padding: 0;
		background-color: var(--white);
		border: 0;
		border-bottom: 1px solid var(--midnight);
		border-radius: 0;
	}

	&::after {
		display: none;
	}

	&::before {
		content: '';
		position: absolute;
		display: block;
		right: 4px;
		top: 8px;
		width: 12px;
		height: 12px;
		transform: rotate(45deg);
		border-right: 2px solid var(--midnight);
		border-bottom: 2px solid var(--midnight);
		transition: .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	}

	&__item {
		font-size: ac(18px, 16px);
	}

	&.is-open {
		&::before {
			top: 14px;
			transform: rotate(-130deg);
		}
	}

	&__list {
		width: 100%;
		overflow: hidden !important;
		display: flex;
		flex-direction: column;

		&--single {
			padding: 0;
		}

		&--dropdown {
			transition: .3s ease;
			opacity: 0;
			transform: scaleY(0);
			transform-origin: 50% 0;
			z-index: 2;
	
			&.is-active {
				opacity: 1;
				transform: scaleY(1);
			}
	
			.choices__item  {
				&::after {
					display: none;
				}
			}
		}
		& > div {
			padding-right: 20px;
			width: calc(100% + 20px);
			max-height: 195px;
		}

		.simplebar-track.simplebar-vertical {
			right: 20px;
			background-color: rgba(2,2,56, .2);
			width: 5px;
		}

		.simplebar-scrollbar::before {
			background-color: var(--midnight);
			opacity: 1;
			top: 0 !important;
			left: 0;
			bottom: 0 !important;
			right: 0;
			border-radius: 0;
		}
	}
}


.terms {
	margin-top: ac(150px, 120px);

	table, td {
		border: 1px solid var(--midnight);
		border-collapse: collapse;
	}

	table {
		width: 100% !important;
		max-width: 1000px;
	}

	tr {
		&:first-child {

			font-weight: 500;
		}
	}

	td {
		padding: ac(15px, 5px, 320, 1440) ac(25px, 5px, 320, 1440);
		font-size: ac(18px, 14px, 320, 1440);
		line-height: ac(30px, 22px);
	}
}


.text-box {
	h1, h2, h3, h4, h5, h6 {
		font-weight: 500;
		margin-bottom: ac(20px, 15px);

		&:not(:first-child) {
			margin-top: ac(50px, 25px);
		}
	}

	h1 {
		font-size: ac(80px, 40px);
		line-height: ac(90px, 50px);
		margin-bottom: ac(40px, 25px);
	}

	h2 {
		font-size: ac(60px, 35px);
		line-height: ac(80px, 45px);
	}

	h3 {
		font-size: ac(40px, 30px);
		line-height: ac(50px, 40px);
	}

	h4 {
		font-size: ac(30px, 25px);
		line-height: ac(40px, 35px);
	}

	h5 {
		font-size: ac(26px, 22px);
		line-height: ac(36px, 32px);
	}

	h6 {
		font-size: ac(22px, 20px);
		line-height: ac(32px, 30px);
	}

	p, ul {
		&:not(:last-child) {
			margin-bottom: ac(10px, 5px);
		}
	}

	ul, ol {
		li {
			position: relative;
			padding-left: ac(27px, 20px);

			&::before {
				content: '';
				position: absolute;
				left: 0;
			}
		}
	}

	ul {
		li {
			&::before {
				top: ac(12px, 11px);
				left: 11px;
				width: 6px;
				height: 6px;
				background-color: var(--midnight);
				border-radius: 50%;
			}
		}
	}

	ol {
  		counter-reset: my-counter;

		li {
			counter-increment: my-counter;

			&::before {
				content: counter(my-counter) ". ";
				color: var(--midnight);
				font-weight: 500;
			}
		}
	}

	a {
		position: relative;
		text-decoration: underline;
		display: inline;

		&:hover {
			color: var(--cyan);

			&::before {
				background: var(--cyan);
			}
		}
	}
}

.pagination {
	margin-top: ac(70px, 55px);
	display: flex;
	justify-content: center;

	span {
		margin: 0 ac(17px, 10px);
		font-size: 15px;
		line-height: 15px;
		font-weight: 500;
		transition: .3s ease;
		user-select: none;
		
		&:hover, &.active {
			color: var(--cyan);
			cursor: pointer;
		}

		&.disabled {
			pointer-events: none;
		}
	}
}

.page404 {
	position: relative;
	background-color: var(--smoke);
	background: linear-gradient(-45deg, var(--cyan), var(--bluish), var(--mint));
	background-size: 400% 400%;
	animation: gradient 20s ease infinite;

	@keyframes gradient {
		0% {
			background-position: 0% 50%
		}

		50% {
			background-position: 100% 50%
		}

		100% {
			background-position: 0% 50%
		}
	}

	&__wrap {
		position: relative;
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		z-index: 2;
	}

	h1 {
		margin-bottom: 0.3em;
		color: var(--white);
	}

	p {
		font-size: ac(22px, 18px);
		line-height: 22px;
		margin-bottom: 2em;
		color: var(--white);
	}

	.btn {
		min-width: 200px;
		background-color: var(--white);

		&:hover {
			background-color: var(--midnight);
		}
	}
}

.cc-nb-buttons-container button, .cc-cp-foot-save, .cc-nb-text, .cc-nb-title, .cc-pc-head-title-text, .cc-pc-head-title-headline, .cc-cp-foot-save, .cc-cp-foot-byline, .cc-cp-body-tabs-item-link, .cc-cp-body-content-entry > *, .cc-custom-checkbox>label {
	font-family: var(--font-main) !important;
}

.termsfeed-com---pc-dialog {
	.cc-cp-body-tabs {
		background: var(--white) !important;
	}

	.cc-pc-head-close {
		color: var(--white) !important;
	}

	.cc-pc-head-close {
		border: 0 !important;

		&:hover {
			color: var(--midnight) !important;
		}
	}

	.cc-cp-body-tabs-item {
		background: var(--white) !important;
		user-select: none;

		&::before {
			display: none !important;
		}

		button {
			color: var(--midnight) !important;
		}
	}

	.cc-cp-body-tabs-item[active=true] {
		background: var(--cyan) !important;

		button {
			color: var(--white) !important;
			box-shadow: unset !important;
		}
	}

	.cc-cp-foot, .cc-pc-head {
		background: var(--cyan) !important;
	}

	.cc-cp-body-content {
		background: var(--white) !important;
	}

	.cc-cp-foot-byline, .cc-pc-head-title-headline {
		color: var(--white) !important;

		a {
			color: var(--white) !important;
			text-decoration: underline;

			&:hover {
				color: var(--midnight) !important;
			}
		}
	}

	.cc-cp-body-content-entry-text, .cc-cp-body-content-entry-title, .cc-custom-checkbox label {
		&, & a {
			color: var(--midnight) !important;
		}
	}

	.is-inactive,  .cc-pc-head-title-text {
		color: var(--white) !important;

		a {
			text-decoration: underline;
			color: var(--white) !important;

			&:hover {
				text-decoration: underline;
				color: var(--cyan) !important;
			}
		}
	}

	@media only screen and (max-width: 551px) {
		.cc-cp-foot {
			flex-direction: column;
			padding-bottom: 20px
		}
	}

	.cc-cp-foot-save {
		border: 0 !important;
		background-color: var(--bluish) !important;
		border-radius: 0px !important;
		margin-bottom: 0 !important;
		text-align: center;
		padding: 9px 15px !important;
		font-weight: 500;
		font-size: 15px;
		transition: .3s ease;
		color: var(--midnight) !important;

		&:hover {
			background-color: var(--midnight) !important;
			color: var(--white) !important;
		}
	}

}
@media only screen and (max-width: 428px) {
	.termsfeed-com---nb .cc-nb-okagree, .termsfeed-com---nb .cc-nb-reject, .termsfeed-com---nb .cc-nb-changep {
		justify-content: space-between !important;
	}
}
.termsfeed-com---palette-dark.termsfeed-com---nb {
	background: var(--white) !important;
	border: 1px solid var(--cyan);
	border-radius: 0;

	.cc-nb-main-container {
		padding: ac(45px, 25px) ac(60px, 25px) ac(30px, 25px) !important
	}

	.cc-nb-title {
		color: var(--midnight) !important;
	}

	.cc-nb-text {
		color: var(--midnight) !important;
		margin-bottom: 0;
	}

	.cc-nb-buttons-container {
		button {
			margin-top: 13px !important;
			border: 0 !important;
			background-color: var(--bluish) !important;
			border-radius: 0px !important;
			margin-bottom: 0 !important;
			text-align: center;
			padding: 9px 15px !important;
			font-weight: 500;
			font-size: 15px;
			transition: .3s ease;
			color: var(--white) !important;

			&:hover {
				background-color: var(--midnight) !important;
			}

			&.cc-nb-changep {
				background-color: var(--cyan) !important;
				color: var(--white) !important;

				&:hover {
					background-color: var(--midnight) !important;
				}
			}
		}
	}
}


@mixin  mob-lg {
	.form {
		label {
			width: 100%;
		}

		button {
			width: 100%;
		}

		.btn-box {
			flex-direction: column;

			.agreement {
				margin-right: 0px;
			}

			.checkBox  {
				margin-bottom: 10px;

				&:last-child {
					margin-bottom: 15px;
				}
			}
		}
	}
}

@mixin mob-sm {
	.container {
		padding: 0 15px;
	}
}

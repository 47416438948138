.markets {
    margin-top: ac(159px, 120px);

    &__wrapper, &__card, &__head {
        display: flex;
    }

    &__wrapper {
        margin-top: ac(86px, 47px);
        flex-wrap: wrap;
        margin-right: ac(-20px, -15px, 1024, 1440);
        margin-bottom: -20px;
    }

    &__card {
        flex-direction: column;
        align-items: flex-start;
        width: calc(100% / 4 - ac(20px, 15px, 1024, 1440));
        margin-bottom: 20px;
        margin-right: ac(20px, 15px, 1024, 1440);
        padding: ac(38px, 20px, 1024, 1440) ac(38px, 20px, 1024, 1440) ac(43px, 20px, 1024, 1440);
        border: 1px solid var(--midnight);
        cursor: pointer;
        transition: .3s ease;

        &:hover {
            background-color: var(--bluish);
            color: var(--midnight);

            .arrow-btn {
                opacity: 1;
            }
        }
    }

    &__head {
        width: 100%;
        justify-content: space-between;
    }

    .arrow-btn {
        margin-top: ac(-15px, -5px, 1024, 1440);
        margin-right: ac(-15px, -5px, 1024, 1440);
        opacity: 0;
        z-index: 1;

        span {
            color: var(--midnight);
        }

        &::before {
            background-color: var(--white);
        }

        &:hover {
            span {
                color: var(--white);
            }

            &::before {
                background-color: var(--midnight);
            }
        }
    }

    &__icon {
        flex: 0 0 auto;
        width: 76px;
        height: 70px;

        img {
            object-fit: contain;
            object-position: left top;
        }
    }

    &__title {
        margin: ac(23px, 20px) 0 ac(28px, 30px);
        font-size: ac(30px, 20px);
        line-height: ac(40px, 30px);
        font-weight: 500;
        min-height: 70px;
    }

    &__descr {
        margin-top: auto;
        max-height: ac(145px, 140px);
        @mixin max-line-length 5;
    }

    &--grow {
        margin-top: ac(142px, 65px);
    }

    @mixin tab {
        &__wrapper {
            margin-right: -20px;
        }

        &__card {
            margin-right: 20px;
            padding: 38px;
            width: calc(100% / 2 - 20px);
        }

        .arrow-btn {
            margin-top: -15px;
            margin-right: -15px;
        }
    }

    @mixin mob-lg {
        &__card {
            width: calc(100% - 20px);

            &:hover {
                .arrow-btn {
                    &::before {
                        background-color: var(--white);
                    }

                    &:hover {
                        &::before {
                            background-color: var(--midnight);
                        }
                    }
                }
            }
        }

        .arrow-btn {
            opacity: 1;

            &::before {
                background-color: var(--bluish);
            }
        }
    }
}
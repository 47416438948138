@font-face {
	font-family: 'icomoon';
	src:  url('../fonts/icomoon.eot?7kngj6');
	src:  url('../fonts/icomoon.eot?7kngj6#iefix') format('embedded-opentype'),
	  url('../fonts/icomoon.ttf?7kngj6') format('truetype'),
	  url('../fonts/icomoon.woff?7kngj6') format('woff'),
	  url('../fonts/icomoon.svg?7kngj6#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
  }
  
  [class^="i-"], [class*=" i-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
  
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  }
  
  .i-arrow:before {
	content: "\e906";
  }
  .i-msn:before {
	content: "\e905";
  }
  .i-twitter:before {
	content: "\e900";
  }
  .i-linkedin:before {
	content: "\e901";
  }
  .i-instagram:before {
	content: "\e902";
  }
  .i-facebook:before {
	content: "\e903";
  }
  .i-arrow-right:before {
	content: "\e904";
  }
  

@font-face {
	font-family: "SpriteGraffiti";
	src: url("../fonts/SpriteGraffiti-Regular.woff2") format("woff2"),
		url("../fonts/SpriteGraffiti-Regular.woff") format("woff"),
		url("../fonts/SpriteGraffiti-Regular.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Satoshi";
	src: url("../fonts/Satoshi-Light.woff2") format("woff2"),
		url("../fonts/Satoshi-Light.woff") format("woff"),
		url("../fonts/Satoshi-Light.ttf") format("truetype");
	font-weight: 300;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: "Satoshi";
	src: url("../fonts/Satoshi-Regular.woff2") format("woff2"),
		url("../fonts/Satoshi-Regular.woff") format("woff"),
		url("../fonts/Satoshi-Regular.ttf") format("truetype");
	font-weight: 400;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: "Satoshi";
	src: url("../fonts/Satoshi-Medium.woff2") format("woff2"),
		url("../fonts/Satoshi-Medium.woff") format("woff"),
		url("../fonts/Satoshi-Medium.ttf") format("truetype");
	font-weight: 500;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: "Satoshi";
	src: url("../fonts/Satoshi-Black.woff2") format("woff2"),
		url("../fonts/Satoshi-Black.woff") format("woff"),
		url("../fonts/Satoshi-Black.ttf") format("truetype");
	font-weight: 900;
	font-display: swap;
	font-style: normal;
}

h1,
h2 {
	font-size: ac(80px, 40px);
	line-height: ac(90px, 50px);
	font-weight: 500;
}

a {
	display: inline-block;
	color: var(--midnight);
	transition: 0.3s ease;

	&:hover {
		color: var(--cyan);
	}
}

@mixin mob-sm {
	h1,
	h2 {
		font-size: 30px;
		line-height: 40px;
	}
}

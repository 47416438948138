.find-job {
    margin-top: 116px;

    &__wrapper {
        display: flex;
    }

    &__sidebar {
        flex: 0 0 auto;
        width: ac(412px, 350px, 1024, 1440);
        margin-right: ac(108px, 25px, 1024, 1440);
        z-index: 10;
    }

    &__sticky {
        margin-top: ac(53px, 35px);
        position: sticky;
        top: 60px;
    }

    &__list {
        width: 100%;
        margin-top: ac(68px, 45px);

        .jobs__card {
            position: relative;
            border-bottom: 1px solid var(--midnight);
            background-color: var(--white);
            padding: 15px 5px 19px 20px;

            &::before {
                content: '';
                display: flex;
                position: absolute;
                top: 0;
                left: 0;
                width: 0%;
                height: 100%;
                background-color: var(--cyan);
                z-index: -1;
                opacity: 0;
                visibility: hidden;
                transform: unset;
                transition: .3s ease-in-out;
            }

            &:nth-child(1){
                z-index: 8;
            }

            &:nth-child(2){
                z-index: 7;
            }

            &:nth-child(3){
                z-index: 6;
            }

            &:nth-child(4){
                z-index: 5;
            }

            &:nth-child(5){
                z-index: 4;
            }

            &:nth-child(6){
                z-index: 3;
            }

            &:nth-child(7){
                z-index: 2;
            }

            &:nth-child(8){
                z-index: 1;
            }

            &:nth-child(even) {
                &::before {
                    transform: unset;
                    left: unset;
                    right: 0;
                }
            }

            &:hover {
                &::before {
                    visibility: visible;
                    opacity: 1;
                    width: 100%;
                }

                color: var(--white);

                .btn {
                    color: var(--white);

                    &::before {
                        background-color: var(--midnight);
                    }
                }
            }

            &:first-child {
                border-top: 1px solid var(--midnight);
            }
        }

        .jobs__icon {
            width: 100%;
            height: 35px;
            margin-bottom: 21px;

            img {
                object-position: left;
            }
        }

        .jobs__name {
            max-width: ac(412px, 250px, 1024, 1440);
        }

        .jobs__descr {
            max-width: ac(299px, 270px, 1024, 1440);
        }

        .jobs__location {
            margin-top: 14px;
            margin-left: 0;
        }
    }

    &__check-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: ac(-20px, -10px, 1024, 1440);
    }

    &__form {
        justify-content: flex-start;
        margin-right: -20px;

        &>* {
            margin-right: 20px;
        }

        label {
            width: 100%;
            margin-bottom: ac(40px, 30px, 1024, 1440);

            &:last-child {
                margin-bottom: 0;
            }

            &.find-job__checkbox {
                overflow: hidden;
                width: auto;
                position: relative;
                display: flex;
                align-items: center;
                flex-direction: row;
                padding: 9px ac(18px, 10px, 1024, 1440);
                margin-right: ac(20px, 10px, 1024, 1440);
                margin-bottom: ac(20px, 10px, 1024, 1440);
                border: 1px solid var(--midnight);
                cursor: pointer;
                transition: .3s ease;

                &:hover {
                    border-color: var(--cyan);
                }

                input {
                    position: absolute;
                    opacity: 0;
                    visibility: 0;
                    z-index: -1;
                }

                span {
                    width: 28px;
                    height: 28px;
                    margin-bottom: 0;
                    margin-right: 10px;

                    img {
                        object-fit: contain;
                        object-position: center;
                    }
                }

                p {
                    font-size: ac(15px, 14px, 1024, 1440);
                    line-height: ac(28px, 18px);
                    font-weight: 500;
                    transition: .3s ease;
                }

                div {
                    position: absolute;
                    left: 0;
                    width: 0%;
                    height: 100%;
                    background-color: var(--cyan);
                    transition: .3s ease;
                    z-index: -1;
                }

                input:checked {
                    & ~ div {
                        width: 100%;
                    }

                    & ~ p {
                        color: var(--white)
                    }
                }
            }
        }
    }

    &__select {
        margin-top: ac(33px, 23px);
        z-index: 2;
        margin-bottom: 0 !important;
    }

    &__range-box {
        width: 100%;
        margin-top: ac(33px, 23px);

        &>span {
            display: inline-block;
            font-weight: 500;
            margin-bottom: ac(25px, 15px);
        }
    }

    &__range {
        width: 100%;
        padding-right: 15px;
        height: 4px;
        border-radius: 12px;
        border: none;
        box-shadow: none;
        background: var(--smoke);

        .noUi-tooltip {
            transform: translate(-50%, 100%);
            left: 50%;
            bottom: -10px;
            display: block !important;
            background: transparent;
            font-size: 16px;
            color: var(--midnight);
            line-height: 1.2;
            padding: 7px 12px 5px;
            border: 1px solid var(--midnight);
            border-radius: 4px;
            transition: 0.3s ease;

            &.hidden {
                display: none !important;
            }
        }

        .noUi-connect {
            background: var(--midnight);
            margin-right: -4px;
        }

        .noUi-handle {
            right: -15px;
            top: -7px;
            background: var(--midnight);
            width: 18px;
            height: 18px;
            border: 2px solid var(--midnight);
            cursor: pointer;
            border-radius: 50%;
            box-shadow: none;
            transition: background 0.3s ease;

            &::before,
            &::after {
                content: none;
            }

            &:hover {
                background: var(--cyan);

                .noUi-tooltip {
                    color: var(--white);
                    background: var(--cyan);
                }
            }
        }
    }

    .pagination {
        margin-left: ac(-18px, 0px, 1024, 1440);
    }

    @mixin tab {
        overflow: hidden;
        
        &__wrapper {
            flex-direction: column;
        }

        &__sidebar {
            width: 100%;
        }

        &__form {
            margin-bottom: -30px;

            label {
                &.find-job__select {
                    width: calc(50% - 20px);
                
                    &:last-child {
                        margin-top: ac(33px, 23px);
                    }
                }
            }
        }

        &__range-box {
            margin-bottom: 40px;
            width: calc(50% - 20px);
        }
        
        &__list {
            display: flex;
            flex-wrap: wrap;
            margin-right: -20px;

            .jobs__card {
                padding: 25px 20px;
                width: calc(100% / 2 - 20px);
                margin-right: 20px;
                height: auto;

                &:nth-child(2) {
                    border-top: 1px solid var(--midnight);
                }
            }

            .jobs__name, .jobs__descr {
                max-width: unset;
            }
    
            .jobs__location {
                padding-top: 14px;
                margin-top: auto;
            }
        }
    }

    @mixin mob-xl {
        &__list {
            display: flex;
            flex-wrap: wrap;
            margin-right: 0px;

            .jobs__card {
                width: 100%;
                margin-right: 0;

                &:nth-child(2) {
                    border-top: 0;
                }
            }
        }

        
    }

    @mixin mob-lg {
        &__form {
            margin-right: 0;

            &>* {
                margin-right: 0;
            }

            label {
                &.find-job__select {
                    width: 100%;

                    &:last-child {
                        margin-top: 0;
                    }
                }
                
                &.find-job__checkbox {
                    width: calc(100% / 2 - 10px);
                }
            }
        }

        &__check-wrapper {
            width: auto;
            margin-right: -10px;
        }

        &__range-box {
            width: 100%;
            margin-bottom: 60px;
        }
    }
}
.team {
    margin-top: ac(198px, 75px);

    &__title {
        font-size: ac(80px, 34px);
	    line-height: ac(90px, 44px);
    }

    &__slider {
        margin-top: ac(60px, 20px);
        width: 100%;
        overflow: visible !important;
    }

    &__img {
        position: relative;
        width: 100%;
        height: ac(196px, 135px, 320, 1440);
    }

    &__link {
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        width: 46px;
        height: 46px;
        z-index: 1;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--midnight);
            transform: rotate(-15deg);
            z-index: -1;
            transition: .3s ease;
        }

        span {
            color: var(--white);
            line-height: 46px;
        }

        &:hover {
            &::before {
                background-color: var(--bluish);
            }
        }
    }

    &__name {
        margin-top: ac(29px, 15px);
        font-size: ac(30px, 25px);
        line-height: ac(40px, 35px);
        font-weight: 500;
    }

    &__position {
        margin-top: ac(11px, 5px);
        font-weight: 500;
        line-height: 144%;
    }
}
.services {
    margin-top: ac(66px, 44px);

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: ac(86px, 35px);
        margin-right: ac(-20px, -10px, 768, 1440);
        margin-bottom: ac(-20px, -10px, 768, 1440);
    }

    &__card {
        position: relative;
        display: flex;
        flex-direction: column;
        width: calc(100% / 3 - ac(20px, 10px, 768, 1440));
        margin-right: ac(20px, 10px, 768, 1440);
        margin-bottom: ac(20px, 10px, 768, 1440);
        border: 1px solid var(--midnight);
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 0%;
            background-color: var(--midnight);
            z-index: -1;
            opacity: 0;
            transition: .3s ease;
        }

        &:hover {
            &::before {
                opacity: 1;
                height: 100%;
            }

            .services__title {
                color: var(--white);
            }

            .services__subtitle {
                opacity: 0;
                transform: translateY(100%);
            }

            .services__hidden {
                opacity: 1;
            }
        }
    }

    &__title, &__subtitle {
        padding: ac(39px, 20px, 768, 1440);
        font-weight: 500;
    }

    &__title {
        min-height: 140px;
        height: 100%;
        font-size: ac(50px, 30px, 768, 1440);
        line-height: ac(60px, 40px, 768, 1440);
        padding-bottom: ac(70px, 30px, 768, 1440);
        transition: .3s ease;
     
        p {
            @mixin max-line-length 2;
            max-height: ac(120px, 80px, 768, 1440);
        }

        
    }

    &__subtitle {
        padding-top: ac(40px, 20px, 768, 1440);
        margin-top: auto;
        height: ac(200px, 120px, 768, 1440);
        font-size: ac(30px, 16px, 768, 1440);
        line-height: ac(40px, 26px, 768, 1440);
        background-color: var(--midnight);
        color: var(--white);
        transition: .3s ease;
        
        p {
            @mixin max-line-length 3;
            max-height: ac(120px, 78px, 768, 1440);
        }
    }

    &__hidden {
        position: absolute;
        width: calc(100% - 2 * ac(39px, 20px, 768, 1440));
        color: var(--white);
        margin-top: ac(35px, 15px);
        font-size: ac(18px, 16px);
        line-height: ac(30px, 22px);
        max-height: ac(120px, 88px);
        @mixin max-line-length 4;
        opacity: 0;
        transition: .3s ease;
    }

    @mixin tab-sm {
        &__wrapper {
            margin-right: ac(-30px, -10px, 551, 768);
            margin-bottom: ac(-30px, -10px, 551, 768);
        }
    
        &__card {
            width: calc(100% / 2 - ac(30px, 10px, 551, 768));
            margin-right: ac(30px, 10px, 551, 768);
            margin-bottom: ac(30px, 10px, 551, 768);
        }
    
        &__title, &__subtitle {
            padding: ac(30px, 20px, 551, 768);
        }
    
        &__title {
            font-size: ac(44px, 30px, 551, 768);
            line-height: ac(54px, 40px, 551, 768);
            padding-bottom: ac(51px, 30px, 551, 768);
    
            p {
                max-height: ac(120px, 80px, 551, 768);
            }
        }
    
        &__subtitle {
            padding-top: ac(40px, 20px, 551, 768);
            height: ac(200px, 120px, 551, 768);
            font-size: ac(26px, 16px, 551, 768);
            line-height: ac(40px, 26px, 551, 768);
            
            p {
                max-height: ac(120px, 78px, 551, 768);
            }
        }

        &__hidden {
            width: calc(100% - 2 * ac(30px, 20px, 551, 768));
        }
    }

    @mixin mob-lg {
        &__wrapper {
            margin-right: 0;
            margin-bottom: -15px;
        }

        &__card {
            width: 100%;
            margin-bottom: 15px;
            margin-right: 0;
        }

        &__title {
            font-size: ac(44px, 24px, 320, 551);
            line-height: ac(54px, 30px, 320, 551);
            padding-bottom: ac(51px, 30px, 320, 551);
            min-height: 120px;
        }
    }
}
.article {
    margin-top: ac(156px, 120px);

    &__img {
        margin-top: ac(60px, 30px);
        height: ac(442px, 180px);
    }

    &__head {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        max-width: 756px;
        margin-top: ac(59px, 30px);
        padding: ac(21px, 15px) ac(48px, 0px, 1024, 1440) ac(21px, 15px) 0;
        border-top: 1px solid var(--midnight);
        border-bottom: 1px solid var(--midnight);

        p {
            max-width: 150px;
            margin-right: ac(97px, 20px);
            font-size: 15px;
            line-height: 15px;
            font-weight: 500;
        }
    }
    
    &__soc-box {
        display: flex;
        margin-right: -12px;
        margin-left: auto;
    }

    &__soc {
        display: flex;
        justify-content: center;
        width: 33px;
        height: 33px;
        background-color: var(--midnight);
        margin-right: 12px;

        span {
            color: var(--white);
            line-height: 33px;
        }

        &:hover {
            background-color: var(--cyan);
        }
    }

    &__text {
        margin-top: ac(50px, 30px);
    }

    &__bottom {
        margin-top: ac(50px, 30px);
        display: flex;
    }

    &__share {
        p {
            font-size: 15px;
            line-height: 15px;
            font-weight: 500;
            margin-bottom: 15px;
        }
    }

    &__download {
        position: relative;
        margin-top: 49px;
        display: flex;
        margin-left: auto;

        &::before {
            content: '';
            position: absolute;
            top: ac(-36px, -15px, 320, 1440);
            left: ac(-31px, -5px, 320, 1440);
            width: ac(391px, 240px, 320, 1440);
            height: ac(117px, 70px, 320, 1440);
            background-color: var(--smoke);
            transform: rotate(4deg);
            z-index: -1;
        }

        span, span::before {
            transition: none !important;
        }

        .arrow-btn {
            margin-top: -12px;

            span {
                transition: .3s ease !important;
            }

            &::before {
                transition: .3s ease !important;
            }

            &:hover {
                span {
                    color: var(--cyan)
                }
            }
        }

        & > span {
            &:first-child {
                font-size: ac(30px, 20px);
                line-height: ac(40px, 30px);
                font-weight: 500;
                margin-right: ac(70px, 30px);
                transition: .3s ease;
            }
        }

        &:hover {
            &>span:first-child {
                transition: .3s ease !important;
            }

            .arrow-btn {
                &::before {
                    transition: .3s ease !important;
                    background-color: var(--midnight);
                }
            }
        }
    }

    &__form {
        margin-top: ac(87px, 35px);
    }

    @mixin  mob-lg {
        &__head {
            flex-direction: column;

            p {
                max-width: unset;
                margin-right: 0;
                margin-bottom: 15px;
            }
        }

        &__soc-box {
            margin-left: 0;
        }

        &__bottom {
            flex-direction: column;
        }

        &__download {
            margin-left: 0;
        }
    }
}
.insights {
    margin-top: ac(156px, 120px);

    &__filter {
        margin-top: ac(80px, 30px);
        display: flex;
        flex-wrap: wrap;
        margin-right: ac(-10px, -5px, 551, 1440);
        margin-bottom: ac(-10px, -5px, 551, 1440);
    }

    &__tab {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: ac(10px, 5px, 551, 1440);
        margin-bottom:  ac(10px, 5px, 551, 1440);
        padding: ac(15px, 10px) ac(19px, 10px);
        font-size: 15px;
        line-height: 15px;
        font-weight: 500;
        border: 1px solid var(--midnight);
        transition: .3s ease;
        cursor: pointer;

        &:hover, &.active {
            background-color: var(--midnight);
            color: var(--white);
        }
    }

    &__card-box {
        margin-top: ac(80px, 30px);
        display: flex;
        flex-wrap: wrap;
        margin-right: -20px;
        margin-bottom: ac(-59px, -40px);
    }

    &__card {
        display: flex;
        flex-direction: column;
        width: calc(100% / 2 - 20px);
        margin-right: 20px;
        margin-bottom: ac(59px, 40px);
    }

    &__img {
        position: relative;
        width: 100%;
        height: ac(442px, 220px, 768, 1440);
    }

    &__corner, &__date {
        font-size: 15px;
        line-height: 15px;
        font-weight: 500;
    }

    &__corner {
        position: absolute;
        top: 0;
        right: 0;
        padding: 15px;
        background-color: var(--white);
    }

    &__text {
        margin-top: 17px;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .btn {
            margin-top: 8px;
        }
    }

    &__col {
        flex: 0 0 auto;
        width: 100%;
        max-width: ac(412px, 290px, 1024, 1440);
        margin-right: 20px;
    }

    &__descr {
        margin-top: 10px;
        font-size: ac(30px, 20px, 1024, 1440);
        line-height: ac(40px, 30px, 1024, 1440);
        font-weight: 500;
    }

    @mixin tab {
        &__text {
            flex-direction: column;

            .btn {
                margin-top: auto;
            }
        }

        &__col {
            max-width: unset;
            margin-right: 0;
            margin-bottom: 5px;
        }

        &__descr {
            font-size: ac(30px, 20px, 320, 1024);
            line-height: ac(40px, 30px, 320, 1024);
        }
    }

    @mixin mob-xl {
        &__card {
            width: calc(100% - 20px);
        }
    }

    @mixin mob-lg {
        &__tab {
            width: calc(100% / 2 - 5px);
            text-align: center;
        }
    }
}
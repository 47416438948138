.reviews {
    margin-top: 16px;

    &__title-box {
        display: flex;
    }

    &__title {
        flex: 0 0 auto;
        max-width: ac(736px, 540px, 1024, 1440);
        margin-right: 20px;
    }

    &__col {
        display: flex;
        flex-direction: column;
        margin-top: ac(75px, 25px);


        p:not(.reviews__subtitle) {
            margin-top: ac(20px, 15px);
        }
    }

    &__subtitle {
        font-size: ac(30px, 20px);
        line-height: ac(40px, 30px);
        font-weight: 500;
    }

    &__slider-box {
        margin-top: ac(54px, 30px);
        display: flex;
    }

    &__slider {
        width: 100% !important;
    }
    
    &__slide {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-height: ac(442px, 300px);
        padding: ac(49px, 25px) ac(108px, 20px, 568, 1440);

        &:hover {
            .reviews__img {
                &::before {
                    opacity: .5;
                }
            }
        }
    }

    &__img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;

        &::before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #000;
            opacity: 0;
            transition: .3s ease;
        }
    }
    
    &__name, &__descr {
        color: var(--white);
        transition: .3s ease;
    }

    &__name {
        font-size: ac(30px, 20px);
        line-height: ac(40px, 30px);
        font-weight: 500;
    }

    &__descr {
        margin-top: 10px;
        max-height: ac(90px, 140px);
        width: calc(100% + 15px);
        padding-right: 15px;

        .simplebar-vertical {
            width: 7px;
            background-color: rgba(255, 255, 255, .3);
            border-radius: 7px;
        }

        .simplebar-scrollbar::before {
            opacity: 1;
            background-color: var(--white);
            left: 0;
            right: 0;
        }

        .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
            top: 0;
            bottom: 0;
        }

    }

    @mixin tab {
        &__title-box {
            flex-direction: column;
        }
    }
}
.testimonials {
    margin-top: ac(140px, 65px);

    &__title {
        max-width: ac(520px, 270px);
    }

    &__wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-top: ac(59px, 55px);
        padding-left: ac(216px, 0px, 375, 1440);
    }

    &__decor {
        position: absolute;
        right: 0;
        top: ac(-136px, -50px);
        width: ac(196px, 100px);
        height: ac(77px, 38px);

        img {
            object-fit: contain;
        }
    }

    &__row {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: auto;
        padding: ac(51px, 20px) 0 ac(41px, 20px);
        border-bottom: 1px solid var(--midnight);
        
        &:first-child {
            border-top: 1px solid var(--midnight);
        }
    }

    &__descr {
        font-size: ac(30px, 20px);
        line-height: ac(40px, 30px);
        font-weight: 500;
    }

    &__name {
        margin-top: 12px;
        margin-left: auto;
        font-size: 15px;
        font-weight: 500;
        color: var(--cyan);
    }

    &--sector {
        margin-top: ac(101px, 65px);

        .testimonials {
            &__title {
                text-align: right;
                margin-left: auto;
            }

            &__wrapper {
                padding-right: ac(216px, 0px, 375, 1440);
                padding-left: 0;
            }

            &__decor {
                right: unset;
                left: 0;
            }

            @mixin mob {
                &__title {
                    text-align: left;
                    margin-left: 0;
                }
            }
        }
    }


}
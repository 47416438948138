.partners {
    margin-top: ac(100px, 80px);

    &--sector {
        margin-top: ac(139px, 80px);
    }

    &__wrapper, &__card {
        display: flex;
    }

    &__wrapper {
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__card {
        position: relative;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: calc(100% / 2 - 10px);
        height: ac(442px, 301px);

        .btn {
            margin-top: ac(55px, 25px);
            opacity: 0;
        }

        &:nth-child(1) {
            .partners__logo {
                margin-top: calc(38px + ac(55px, 25px));
            }

            .btn {
                margin-top: ac(55px, 39px);
            }
        }

        &:hover {
            .partners__bg {
                &::before {
                    opacity: 1;
                }
            }

            .btn {
                opacity: 1;
            }
        }
    }

    &__bg {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--midnight-o);
            opacity: 0;
            transition: .3s ease;
        }
    }

    &__logo {
        margin-top: calc(48px + ac(55px, 25px));
        width: 70%;
        max-width: ac(292px, 178px);
        height: 54px;

        img {
            object-fit: contain;
        }
    }

    @mixin tab-sm {
        &__card {
            width: 100%;

            &:not(:last-child) {
                margin-bottom: 20px;
            }

            .btn {
                opacity: 1;
            }
        }
    }
}
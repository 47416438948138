.about-us {
	margin-top: ac(489px, 275px);

	&__wrapper,
	&__col {
		display: flex;
	}

	&__col {
		flex-direction: column;
		align-items: flex-start;

		&:nth-child(1) {
			flex: 0 0 auto;
			width: 100%;
			max-width: ac(520px, 300px, 1024, 1440);
			margin-right: ac(128px, 45px, 1024, 1440);

			.about-us__text {
				margin-bottom: auto;
				font-size: ac(30px, 20px);
				line-height: ac(40px, 30px);
				font-weight: 500;
			}
		}

		&:nth-child(2) {
			width: 100%;

			.about-us__title {
				margin-bottom: ac(66px, 38px, 1024, 1440);
			}

			.btn {
				display: none;
			}
		}

		.btn {
			margin-top: ac(30px, 42px);
		}
	}

	&__title {
		margin-top: ac(-5px, 0px, 1024, 1440);
	}

	&--about {
		margin-top: ac(79px, 54px);

		.about-us {
			&__wrapper {
				margin-top: ac(66px, 50px);
			}

			&__col {
				&:nth-child(2) {
					margin-top: ac(18px, 40px);

					.about-us__text {
						font-size: 18px;
						line-height: 30px;
					}
				}
			}
		}
	}

	&--grow {
		margin-top: ac(139px, 65px);

		.about-us {
			&__wrapper {
				margin-top: ac(32px, 15px);
			}

			&__col {
				.footer__link {
					margin-top: ac(45px, 20px);
					margin-left: auto;

					span:first-child {
						color: var(--midnight);
					}

					&:hover {
						span:first-child {
							color: var(--bluish);
						}

						.arrow-btn::before {
							background-color: var(--midnight);
						}
					}
				}

				&:nth-child(1) {
					margin-top: ac(130px, 25px);
					max-width: ac(520px, 400px, 1024, 1440);
				}

				&:nth-child(2) {
					.about-us__title {
						text-align: right;
						margin-bottom: 0;
					}
				}
			}
		}
	}

	&--sector {
		margin-top: ac(337px, 275px);
	}

	&--work {
		margin-top: ac(115px, 65px);

		.about-us__col:nth-child(2) .about-us__title {
			margin-bottom: ac(61px, 38px, 1024, 1440);
		}

		.about-us__col:nth-child(2) .btn {
			margin-top: ac(49px, 25px);
			display: inline-flex;
			background-color: var(--mint);

			&:hover {
				background-color: var(--midnight);
				color: var(--white);
			}
		}
	}

	@mixin tab {
		&__wrapper {
			flex-direction: column;
		}

		&__col {
			&:nth-child(1) {
				max-width: 100%;

				.btn {
					display: none;
				}
			}

			&:nth-child(2) {
				margin-top: 50px;

				.btn {
					display: inline-flex;
				}
			}
		}

		&--grow {
			.about-us__wrapper {
				flex-direction: column-reverse;
			}
	
			.about-us__col {
				.footer__link {
					margin-left: 0;
				}

				&:nth-child(1) {
					max-width: 600px;
				}

				&:nth-child(2) {
					margin-top: 0;

					.about-us__title {
						text-align: right;
						align-self: flex-end;
					}
				}
			}
		}
	
	}

}

.header {
	position: fixed;
	width: 100%;
	padding: 13px 0;
	border-bottom: 2px solid var(--smoke);
	background-color: var(--white);
	transition: 0.3s ease;
	z-index: 50;

	&__wrap, &__dropdown-menu, &__dropdown-link, &__icon {
		display: flex;
	}

	&__logo img, &__dropdown, &__layout {
		position: absolute;
	}

	&__wrap {
		align-items: center;
	}

	&__logo {
		position: relative;
		width: 138px;
		height: 16px;
		margin-right: 78px;

		img {
			object-fit: contain;
			transition: 0.3s ease;

			&:nth-child(2) {
				opacity: 0;
			}
		}

		&:hover {
			img {
				&:nth-child(1) {
					opacity: 0;
				}

				&:nth-child(2) {
					opacity: 1;
				}
			}
		}
	}

	&__navbar-box {
		margin-left: auto;
	}

	&__link {
		font-size: 15px;
		line-height: 1em;
		font-weight: 500;
		transition: .3s ease;

		&:not(:last-child) {
			margin-right: 36px;
		}

		
	}

	[title="Sectors"] {
		position: relative;

		&::before, &::after {
			content: '';
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			background-color: var(--midnight);
			transition: .3s ease;
		}

		&::before {
			width: 7px;
			height: 1px;
			right: -10px;
		}

		&::after {
			right: -7px;
			width: 1px;
			height: 7px;
		}

		&:hover, &.active {
			color: var(--cyan);

			&::before, &::after {
				background-color: var(--cyan);
			}
		}

		&.active {
			&::after {
				height: 0;
			}
		}
	}

	&__dropdown {
		padding: 40px 0;
		width: 100%;
		bottom: -1px;
		transform: translateY(100%);
		background-color: var(--white);
		visibility: hidden;
		opacity: 0;
		transition: 0.3s ease;

		&.active {
			opacity: 1;

			&,
			& ~ .header__layout {
				visibility: visible;
			}

			& ~ .header__layout {
				opacity: 0.3;
			}
		}
	}

	&__layout {
		width: 100%;
		height: 100vh;
		bottom: -1px;
		transform: translateY(100%);
		background-color: var(--white);
		visibility: hidden;
		opacity: 0;
		transition: 0.3s ease;
		z-index: -1;
		cursor: pointer;
	}

	&__dropdown-menu {
		flex-direction: column;
	}

	&__dropdown-link {
		align-items: center;
		font-size: 30px;
		line-height: 30px;
		font-weight: 500;

		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}

	&__icon {
		flex: 0 0 auto;
		width: 40px;
		height: 37px;
		margin-right: 58px;

		img {
			object-fit: contain;
		}
	}

	&__accordion {
		overflow: hidden;
		margin-bottom: 20px;

		.header__link {
			position: relative;
			cursor: pointer;

			&:not(:last-child) {
				margin-bottom: 0;
			}

			&:hover {
				color: var(--cyan);
			}
		}

		.header__dropdown-menu {
			max-height: 0;
			overflow: hidden;
			transition: 0.3s ease;
			opacity: 0;
		}

		.header__dropdown-link {
			font-size: 20px;

			&:first-child {
				margin-top: 13px;
			}

			&:not(:last-child) {
				margin-bottom: 11px;
			}
		}

		.header__icon {
			margin-right: 41px;
		}

		&.active {
			margin-bottom: 28px;

			.header__link {
				color: var(--cyan);
				transition: 0.3s ease;
			}

			.header__dropdown-menu {
				max-height: 500px;
				opacity: 1;
			}
		}
	}

	@mixin tab {
		padding: 30px 0;

		&.active {
			.header__navbar-box {
				transform: translate(0%, 100%);
				opacity: 1;
				visibility: visible;
			}

			.header__layout {
				opacity: 0.3;
				visibility: visible;
			}

			.header__burger {
				span {
					&:nth-child(1) {
						width: 0;
						opacity: 0;
					}

					&:nth-child(2) {
						transform: rotate(45deg);
					}

					&:nth-child(3) {
						width: 32px;
						transform: rotate(-45deg) translate(5px, -5px);
					}
				}
			}
		}

		.header__simplebar {
			max-height: 100%;
			padding-right: 20px;
			width: calc(100% + 20px);
		}

		.simplebar-track {
			width: 10px;
			border-radius: 20px;
			cursor: pointer;
		}

		.simplebar-content-wrapper {
			padding: 0 ac(30px, 15px) 0 0;
		}

		.simplebar-scrollbar::before {
			background-color: var(--cyan);
			opacity: 1;
		}

		&__logo {
			width: 160px;
			height: 19px;
		}

		&__burger {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-end;
			margin-left: auto;
			width: 41px;
			height: 16px;
			cursor: pointer;

			span {
				width: 100%;
				height: 2px;
				background-color: var(--midnight);
				transition: 0.3s ease;

				&:nth-child(1) {
					width: 24px;
				}

				&:nth-child(2) {
					width: 32px;
				}
			}

			&:hover {
				span {
					background-color: var(--cyan);
				}
			}
		}

		&__navbar-box {
			width: 100%;
			max-width: 500px;
			height: calc(100vh - 81px);
			height: calc((var(--vh, 1vh) * 100) - 81px);
			position: absolute;
			right: 0;
			bottom: -1px;
			padding: 50px 36px;
			background-color: var(--white);
			transform: translate(100%, 100%);
			opacity: 0;
			visibility: hidden;
			transition: 0.3s ease;
		}

		&__navbar {
			width: 100%;
			display: flex;
			flex-direction: column;
		}

		&__link {
			font-size: 35px;
			line-height: 45px;

			&:not(:last-child) {
				margin-right: 0;
				margin-bottom: 20px;
			}
		}
	}

	@mixin mob-sm {
		&__navbar-box {
			padding: 35px 15px;
		}
	}
}

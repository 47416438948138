.banner {
    margin-top: ac(144px, 120px);
    
    &, &__card {
        position: relative;
    }

	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
	}

	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding-top: ac(50px, 60px);
	}

	&__title {
		max-width: ac(885px, 237px);
		color: var(--white);

		&:nth-child(2) {
			margin-top: ac(-13px, 30px);
			margin-left: auto;
			max-width: ac(503px, 252px);
			text-align: right;
		}
	}

	&__card {
		margin-top: ac(8px, 100px);
		padding: ac(50px, 40px) ac(82px, 40px) ac(50px, 40px) 0;
		max-width: 602px;

		&::before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			width: calc(100% + 50vw);
			height: 100%;
			background-color: var(--white);
			z-index: -1;
		}

		.btn {
			margin-top: ac(35px, 41px);
		}
	}

	&__subtitle {
		font-weight: 500;
		font-size: ac(50px, 20px);
		line-height: ac(60px, 30px);
		color: var(--white);
		margin-top: ac(23px, 15px);
		margin-left: auto;
		max-width: ac(844px, 452px);
		text-align: right;
	}

	&--about {
		margin-top: ac(140px, 66px);

		.banner {
			&__card {
				margin-top: ac(40px, 20px);
				padding: ac(50px, 20px) ac(82px, 20px) ac(54px, 20px) 0;
			}

			&__btn-box {
				margin-top: ac(35px, 20px);
				display: flex;
				flex-wrap: wrap;
				margin-right: -20px;
				margin-bottom: -10px;

				.btn {
					margin-top: 0;
					margin-right: 20px;
					margin-bottom: 10px;

					&:nth-child(2) {
						background-color: var(--bluish);
						color: var(--white);

						&:hover {
							background-color: var(--midnight);
						}
					}
				}
			}
		}
	}

	&--work,
	&--sector {
		margin-top: ac(169px, 100px);

		.banner {
			&__wrapper {
				padding-top: 0;
			}

			&__corner {
				display: flex;
				justify-content: flex-end;
				
				p {
					padding: 15px;
					font-weight: 500;
					font-size: 15px;
					line-height: 15px;
					background-color: var(--white);
				}
			}

			&__card {
				margin-top: ac(208px, 100px);
				max-width: 520px;
				padding-top: ac(42px, 40px);

				&>span {
					font-weight: 500;
					font-size: 15px;
					line-height: 15px;
				}
				
				&>p {
					margin-top: ac(10px, 5px);
					font-size: ac(30px, 25px);
					line-height: ac(40px, 35px);
					font-weight: 500;
				}

				.btn {
					margin-top: ac(47px, 20px);
					background-color: var(--bluish);
					color: var(--midnight);

					&:hover {
						background-color: var(--midnight);
						color: var(--white);
					}
				}
			}
		}
	}

	&--work {
		margin-top: ac(140px, 75px);
	}

	@mixin mob {
		&__subtitle {
			margin-left: 0;
			text-align: left;
		}

		&--about {
			.banner__btn-box {
				margin-right: 0;

				.btn {
					width: 100%;
					margin-right: 0;
				}
			}
		}
	}
}

.footer {
	padding: ac(50px, 60px) 0;
	margin-top: ac(195px, 132px);
	background-color: var(--midnight);
	color: var(--white);

	.container {
		display: flex;
		flex-direction: column;
	}

	&--grow {
		margin-top: 18px;
	}

	&--sector {
		margin-top: ac(154px, 100px);
	}

	&--article {
		margin-top: ac(140px, 100px);
	}

	&--insights {
		margin-top: ac(140px, 100px);
	}

	&__title {
		max-width: 691px;
	}

	&--work {
		margin-top: ac(140px, 75px);
	}

	&__btn-box,
	&__link,
	&__wrapper,
	&__col,
	&__address,
	&__soc-box,
	&__soc {
		display: flex;
	}

    &__link, &__wrapper {
        justify-content: space-between;
    }

    &__address, &__soc-box {
        flex-direction: column;
    }

	&__btn-box {
		margin-top: ac(61px, 31px);

		.footer__link {
			&:nth-child(2) {
				&>span {
					&:first-child {
						margin-right: ac(56px, 20px, 1024, 1440);
					}
				}
			}
		}
	}

	&__link {
		&:not(:last-child) {
			margin-right: ac(135px, 50px);
		}

		.i-arrow {
			color: var(--midnight);
		}

		& > span {
			&:first-child {
				font-size: ac(30px, 25px);
				line-height: ac(80px, 54px);
				font-weight: 500;
				color: var(--white);
				margin-right: ac(20px, 10px);
				transition: .3s ease;
			}
		}

		&:hover {
			& > span {
				&:first-child {
					color: var(--cyan);
				}
			}

			.arrow-btn {
				span {
					color: var(--midnight);
				}

				&::before {
					background-color: var(--white);
				}
			}
		}
	}

	&__wrapper {
		align-items: flex-start;
		width: 100%;
		margin-top: ac(70px, 60px);
		margin-right: ac(-45px, -15px, 1024, 1440);

		a {
			color: var(--white);

			&:hover {
				color: var(--cyan);
			}
		}
	}

	&__col {
		flex-wrap: wrap;
		align-items: flex-start;
		margin-right: ac(45px, 15px, 1024, 1440);

		&--address, &--copy {
			max-width: ac(382px, 230px, 1024, 1440);
			width: 100%;
		}

		&--address, &--contacts, &--copy {
			flex-direction: column;
		}

		&--address {
			align-items: flex-start;

			span {
				&:not(:first-child) {
					margin-top: 20px;
				}

				margin-bottom: 12px;
				font-weight: 500;
				font-size: ac(30px, 25px);
				line-height: ac(40px, 35px);
			}
		}

		&--contacts {
			span {
				&:not(:first-child) {
					margin-top: 20px;
				}
				font-weight: 500;
				font-size: ac(30px, 25px);
				line-height: ac(40px, 35px);
			}

			a {
				margin-top: 5px;
			}
		}

		&--copy {
			p {
				&:not(:last-child) {
					margin-bottom: 15px;
				}
			}
		}
	}

	&__soc-box {
		margin-bottom: ac(10px, 9px);
	}

	&__soc {
		justify-content: center;
		width: 55px;
		height: 55px;
		background-color: var(--white);

		&:not(:last-child) {
			margin-bottom: 20px;
		}

		span {
			color: var(--cyan);
			font-size: 24px;
			line-height: 55px;
			transition: 0.3s ease;
		}

		&:hover {
			background-color: var(--cyan);

			span {
				color: var(--white);
			}
		}
	}

	&__bottom {
		padding-top: ac(45px, 30px);
		border-top: 1px solid rgba(255,255,255, .2);
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		align-items: center;
		margin-top: ac(45px, 30px);
		margin-bottom: -15px;
	}

	&__privacy {
		margin: 0 ac(25px, 10px) 15px;
		font-size: 16px;
		line-height: 20px;
		font-weight: 300;
		color: var(--white);
	}

	@mixin tab {
		&__wrapper {
			flex-wrap: wrap;
			width: auto;
			margin-bottom: -35px;
		}

		&__col {
			width: calc(100% / 2 - 15px);
			max-width: unset;
			margin-bottom: 35px;
		}

		&__col--address {
			order: 1;
		}

		&__col--contacts {
			order: 2;
		}

		&__col--copy {
			order: 3;
		}

		&__soc-box {
			flex-direction: row;
			order: 4;
			margin-right: 15px;
			margin-bottom: 35px;
		}

		&__soc {
			&:not(:last-child) {
				margin-bottom: 0px;
				margin-right: 15px;
			}
		}

		&__btn-box {
			flex-direction: column;
		}

		&__link {
			width: 100%;
			max-width: 376px;

			&:not(:last-child) {
				margin-right: 0;
				margin-bottom: 32px;
			}

			.arrow-btn {
				margin-right: 5px;
			}
		}
	}

	@mixin mob-xl {
		&__wrapper {
			margin-right: 0;
		}

		&__col {
			margin-right: 0;
			width: 100%;
		}

		&__col--copy {
			width: 100%;
			order: 4;
		}

		&__soc-box {
			order: 3;
			margin-right: 0;
		}
	}

	@mixin mob-sm {
		&__link {
			& > span {
				&:first-child {
					font-size: 22px;
				}
			}
		}
	}
}

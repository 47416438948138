.mission {
    margin-top: ac(140px, 65px);

    &__head {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        &>* {
            text-align: right;
        }

        h2 {
            & + div {
                margin-top: ac(67px, 25px);
            }
        }

        p {
            max-width: 736px;
            font-size: ac(30px, 20px);
            line-height: ac(40px, 30px);
            font-weight: 500;
        }
    }

    &__title-box {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 19px;
        max-width: ac(600px, 300px, 320, 1440);
        padding: ac(151px, 50px, 320, 1440) 0 ac(99px, 50px, 320, 1440);
        z-index: 2;

        &::before {
            content: '';
            position: absolute;
            right: -102px;
            top: 4px;
            width: calc(100% + 50vw);
            height: 100%;
            background-color: var(--midnight);
            transform: rotate(6.44deg);
            z-index: -1;
        }
    }

    &__title {
        position: relative;
        color: var(--white);
        font-size: ac(80px, 40px);
        line-height: ac(90px, 50px);

        span {
            position: absolute;
            right: ac(-102px, -60px, 320, 1440);
            top: ac(4px, 7px, 320, 1440);
            width: ac(81px, 40px, 320, 1440);
            height: ac(81px, 40px, 320, 1440);
        }
    }

    &__subtitle {
        margin-top: 32px;
        color: var(--white);
        font-size: ac(30px, 20px);
        line-height: ac(40px, 30px);
        font-weight: 500;
    }

    &__slider {
        margin-top: ac(-9px, -15px);
        width: 100% !important;
        height: ac(412px, 302px, 1024, 1440);
        overflow: visible !important;
    }

    &--work {
        margin-top: ac(154px, 65px);

        .mission {
            &__slider {
                margin-top: ac(60px, 25px);
            }
            
            &__soc-box,
            &__soc {
                display: flex;
            }

            &__title-box {
                padding: 0;
                margin-top: 0;
                flex-direction: row;
                flex-wrap: wrap;
                max-width: 100%;

                &::before {
                    display: none;
                }
            }

            &__title {
                margin-right: 10px;
                color: var(--midnight);
            }

            &__soc-box {
                margin-left: auto;
                margin-top: ac(22px, 10px, 1024, 1440);
            }

            &__soc {
                justify-content: center;
                width: 55px;
                height: 55px;
                background-color: var(--mint);

                &:not(:last-child) {
                    margin-right: 20px;
                }

                span {
                    color: var(--midnight);
                    font-size: 24px;
                    line-height: 55px;
                    transition: 0.3s ease;
                }

                &:hover {
                    background-color: var(--cyan);

                    span {
                        color: var(--white);
                    }
                }
            }

        }
    }
    @mixin tab {
        &__slider {
            height: ac(466px, 230px, 551, 1024);
        }

        &--work {
            .mission__title-box {
                flex-direction: column;
            }

            .mission__soc-box {
                margin-top: 20px;
            }
        }
    }

    @mixin mob-lg {
        &__slider {
            height: ac(474px, 299px, 375, 551);
        }
    }
}
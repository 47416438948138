.ready {
    margin-top: ac(73px, 87px);

    &--about {
        .ready {
            &__wrapper {
                margin-left: auto;
                padding: ac(93px, 40px, 651,1440) ac(200px, 25px, 651, 1440) 23px 62px;
        
                &::before {
                    right: unset;
                    left: ac(74px, 5px);
                    transform: rotate(-3.9deg);
                }

                .arrow-btn {
                    margin: 8px 70px -20px -70px;

                    &::before {
                        transform: rotate(15deg);
                    }
                }
            }

        }
    }

    &__wrapper {
        padding: ac(72px, 40px) 0 41px ac(110px, 0px, 651, 1440);
        position: relative;
        display: flex;
        align-items: flex-end;
        width: 100%;
        max-width: ac(930px, 350px);

        &::before {
            content: '';
            position: absolute;
            right: ac(74px, -15px);
            top: 0;
            width: calc(100% + 50vw);
            height: 100%;
            background-color: var(--smoke);
            transform: rotate(3.9deg);
            z-index: -1;
        }
    }

    &__text {
        margin-right: ac(100px, 0px);
        font-size: ac(50px, 35px);
        line-height: ac(60px, 45px);
        font-weight: 500;
    }

    .arrow-btn {
        margin: ac(0px, -10px) ac(0px, 11px) ac(-13px, 0px) 0;

        span {
            font-size: ac(42px, 38px);
            margin-top: ac(3px, 2px);
            margin-left: ac(4px, 2px);
        }
    }


    &--work,
    &--sector {
        margin-top: ac(168px, 70px);

        .ready {
            &__wrapper {
                padding: 0;
                max-width: unset;

                &::before {
                    display: none;
                }
            }

            &__btn-box {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 100%;
            }

            &__link {
                display: flex;

                &:not(:last-child) {
                    margin-bottom: ac(92px, 30px);

                    & > span {
                        &:first-child {
                            margin-right: ac(85px, 40px);
                        }
                    }

                    .arrow-btn {
                        &::before {
                            background-color: var(--cyan);
                        }
                        
                    }

                    &:hover {
                        .arrow-btn {
                            &::before {
                                background-color: var(--bluish);
                            }
                            
                        }
                    }
                }

                .arrow-btn {
                    margin: ac(-12px, -7px) 0 0;
                    width: ac(132px, 50px);
                    height: ac(132px, 50px);

                    span {
                        font-size: ac(41px, 18px);
                        line-height: ac(136px, 50px);
                        margin-left: ac(-3px, 0px, 1024, 1440);
                    }

                    &::before {
                        width: ac(132px, 50px);
                        height: ac(132px, 50px);
                        transform: rotate(15deg);
                        background-color: var(--bluish);
                    }
                }
        
                & > span {
                    &:first-child {
                        font-size: ac(80px, 20px);
                        line-height: ac(90px, 30px);
                        font-weight: 500;
                        margin-right: ac(63px, 30px);
                        transition: .3s ease;
                    }
                }
        
                &:hover {
                    & > span {
                        &:first-child {
                            color: var(--cyan);
                        }
                    }
        
                    .arrow-btn {
                        span {
                            color: var(--midnight);
                        }
        
                        &::before {
                            background-color: var(--cyan);
                        }
                    }
                }
            }
        }
    }

    &--work {
        margin-top: ac(198px, 65px);

        .ready {
            &__wrapper {
               flex-direction: row;
            }

            &__link {
                .arrow-btn {
                    span {
                        margin-top: 0px;
                    }

                    &::before {
                        background-color: var(--grass);
                    }
                }
            }
        }
    }

    @mixin mob-xl {
        &__wrapper {
            flex-direction: column;
            padding-bottom: 0;

            &::before {
                height: calc(100% - 74px);
            }
        }

        &--about {
            .ready__wrapper {
                flex-direction: column-reverse;

                .arrow-btn {
                    margin-right: auto;
                }
            }
        }
    }

    @mixin mob-sm {
        &__wrapper {
            width: 95%;
        }
    }
}
:root {
    --font-main: "Satoshi", sans-serif;
    --font-sec: "SpriteGraffiti", sans-serif;
    --white: #FFFFFF;
    --smoke: #F5F5F5;
    --midnight: #020238;
    --cyan: #3282E0;
    --bluish: #14C3E0;
    --midnight-o: rgba(2, 2, 56, 0.24);
    --mint: #4BC77D;
    --grass: #C0ED96;
}
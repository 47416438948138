.jobs {
    margin-top: ac(140px, 120px);

    &__head, &__subtitle, .swiper-wrapper, &__card {
        display: flex;
    }

    &__card, &__name, &__location, &__descr {
        width: 100%;
    }

    &__title {
        flex: 0 0 auto;
        max-width: ac(536px, 446px, 1024, 1440);
        margin-right: ac(220px, 20px, 1024, 1440);
    }

    &__subtitle {
        flex-direction: column;
        align-items: flex-end;
        margin-top: ac(46px, 30px);

        p {
            font-size: ac(30px, 20px);
            line-height: ac(40px, 30px);
            font-weight: 500;
        }

        .btn {
            margin-top: ac(26px, 41px);
        }
    }

    &__card-box {
        margin-top: ac(58px, 65px);
    }

    &__slider {
        width: 100% !important;
        overflow: visible !important;
    }

    .swiper-wrapper {
        flex-direction: column;
        align-items: flex-end;
    }

    .swiper-slide {
        max-width: 1060px;
        border-bottom: 1px solid var(--midnight);
        background-color: var(--white);

        &::before {
            content: '';
            display: flex;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--cyan);
            z-index: -1;
            transform: translateX(-50%);
            opacity: 0;
            visibility: hidden;
            transition: .3s ease-in-out;
            pointer-events: none;
        }

        &:nth-child(1){
            z-index: 4;
        }

        &:nth-child(2){
            z-index: 3;
        }

        &:nth-child(3){
            z-index: 2;
        }

        &:nth-child(4){
            z-index: 1;
        }

        &:nth-child(even) {
            &::before {
                transform: translateX(50%);
            }
        }

        &:hover {
            &::before {
                visibility: visible;
                opacity: 1;
                transform: translateX(0%);
            }

            .jobs__card {
                color: var(--white);

                .btn {
                    color: var(--white);

                    &::before {
                        background-color: var(--midnight);
                    }
                }
            }
        }

        &:first-child {
            border-top: 1px solid var(--midnight);
        }
    }

    &__card {
        flex-wrap: wrap;
        padding: 52px 5px 19px 20px;
        transition: .3s ease;

        .btn {
            position: absolute;
            right: 2px;
            bottom: 0;
            transform: translateY(50%);
            z-index: 1;
        }
    }

    &__icon {
        flex: 0 0 auto;
        width: 39px;
        height: 39px;
        margin-top: 8px;
        margin-right: 50px;

        img {
            object-fit: contain;
            object-position: left;
        }
    }

    &__name {
        flex: 0 0 auto;
        max-width: ac(460px, 430px, 1024, 1440);
        margin-right: 20px;
        margin-bottom: 20px;
        font-size: ac(30px, 25px);
        line-height: ac(40px, 35px);
        font-weight: 500;
    }

    &__location {
        margin-top: 5px;
        margin-left: 89px;
        font-size: 15px;
        line-height: 15px;
        font-weight: 500;
    }

    &__descr {
        max-width: ac(408px, 370px, 1024, 1440);
        margin-left: auto;
        font-size: 18px;
        line-height: 30px;
        max-height: 120px;
        @mixin max-line-length 4;
    }

    &__pagination {
        display: none;
    }

    &--work {
        .swiper-slide {
            max-width: 972px;

            &::before {
                background-color: var(--mint);
            }
        }
        
        .jobs {
            &__location {
                margin-left: 0;
            }
        }
    }

    &--sector {
        .jobs {
            &__head {
                justify-content: space-between;
            }

            &__title {
                max-width: ac(600px, 446px);
            }

            &__subtitle {
                margin-top: ac(87px, 0px);
            }

            &__icon {
                height: 40px;
                width: 32px;
                margin-right: 56px;
            }
        }
    }

    @mixin tab {
        &__head {
            flex-direction: column;
        }

        &__title {
            max-width: unset;
            margin-right: 0;
        }

        &__subtitle {
            align-items: flex-start;
        }

        &__card-box {
            display: flex;
            position: relative;
            padding-bottom: 72px;
        }

        .swiper-wrapper {
            align-items: unset;
            flex-direction: row;
        }

        .swiper-slide {
            max-width: unset;
            border-top: 1px solid var(--midnight);

            &:before {
                display: none;
            }

            &:hover {
                .jobs__card {
                    color: var(--midnight);
                    background-color: var(--white);

                    .btn {
                        color: var(--midnight);

                        &:before {
                            background-color: var(--smoke);
                        }

                        &:hover {
                            color: var(--white);

                            &::before {
                                background-color: var(--midnight);
                            }
                        }
                    }
                }
            }
        }

        &__card {
            height: 100%;
            padding: 45px 0 49px;
            flex-direction: column;

        }

        &__icon {
            width: 60px;
            height: 56px;
            margin-bottom: 30px;
        }

        &__name {
            max-width: unset;
            margin-right: 0;
        }

        &__descr {
            max-width: unset;
            margin-left: 0;
        }

        &__location {
            margin-top: 40px;
            margin-left: 0;
        }

        &__pagination {
            display: block;
            bottom: -8px !important;
        }

        .swiper-pagination-bullet {
            position: relative;
            width: 14px;
            height: 14px;
            margin: 0 5px !important;
            border: 1px solid var(--midnight);
            background-color: transparent;
            opacity: 1;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                transform: scale(0);
                height: 100%;
                width: 100%;
                background-color: var(--midnight);
                border-radius: 100%;
                transition: .5s ease;
            }

            &:hover {
                &::before {
                    transform: scale(1);
                }
            }
        }

        .swiper-pagination-bullet-active {
            &::before {
                transform: scale(1);
            }
        }
    }
}
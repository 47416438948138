.advantages {
    margin-top: ac(60px, 30px);

    &__subtitle {
        display: flex;
        margin-top: ac(31px, 15px);
        margin-left: ac(2px, 0px, 1024, 1440);

        div {
            width: 100%;
            max-width: 520px;

            &:first-child {
                font-size: ac(30px, 20px);
                line-height: ac(40px, 30px);
                font-weight: 500;
                margin-right: ac(18px, 10px);
            }
        }
    }

    &__slider-box {
        margin: ac(70px, 35px) -20px ac(-70px, -25px) 0;
        position: relative;
    }

    &__slider {
        width: 100% !important;
        overflow: visible !important;
    }

    .swiper-wrapper {
        flex-wrap: wrap;
    }

    &__pagination {
        display: none;
    }

    &__slide {
        width: calc(100% / 3 - 20px);
        margin-right: 20px;
        margin-bottom: ac(70px, 25px);
    }

    &__icon {
        width: 62px;
        height: 62px;

        img {
            object-fit: contain;
            object-position: left bottom;
        }
    }

    &__name {
        margin-top: ac(21px, 15px);
        font-size: ac(30px, 20px);
        line-height: ac(40px, 30px);
        font-weight: 500;
    }

    &__descr {
        margin-top: ac(24px, 18px);
    }

    @mixin tab {
        &__slider-box {
            margin: ac(70px, 35px) 0 0;
            position: relative;
        }

        .swiper-wrapper {
            flex-wrap: unset;
        }

        &__slide {
            width: initial;
            margin: 0;
        }

        &__pagination {
            display: block;
            bottom: -46px !important;
        }

        .swiper-pagination-bullet {
            position: relative;
            width: 14px;
            height: 14px;
            margin: 0 5px !important;
            border: 1px solid var(--midnight);
            background-color: transparent;
            opacity: 1;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                transform: scale(0);
                height: 100%;
                width: 100%;
                background-color: var(--midnight);
                border-radius: 100%;
                transition: .5s ease;
            }

            &:hover {
                &::before {
                    transform: scale(1);
                }
            }
        }

        .swiper-pagination-bullet-active {
            &::before {
                transform: scale(1);
            }
        }
    }

    @mixin tab-sm {
        &__subtitle {
            flex-direction: column;

            div {
                &:first-child {
                    margin-right: 0px;
                    margin-bottom: ac(30px, 15px);
                }
            }
        }
    }
}